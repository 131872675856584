import React from "react";
import { Link } from "react-router-dom";
function FinanceNav() {
  return (
    <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden md:max-w-2xl mt-5">
      <div className="p-4 bg-[#E9E9E9] text-black text-lg font-bold">
        Finance
      </div>
      <div className="p-6 grid grid-cols-3 gap-4">
        <Link className="flex font-semibold  justify-start col-span-2">
          Payments
        </Link>
      </div>
    </div>
  );
}

export default FinanceNav;
