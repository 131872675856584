import React from "react";

function CurrentLevel() {
  return (
    <div className=" flex items-center gap-2">
      <h2 className="">Current Level</h2>
      <div className=" rounded-xl  bg-zinc-200 p-4 font-bold">New</div>
    </div>
  );
}

export default CurrentLevel;
