import React from "react";
import tick from "../../../../../assets/svg_icons/green-tick.svg";
const items = [
  "30-Minute Consultation or Comprehensive Questionnaire.",
  "	Attention Grabbing Summary that captures your Abilities.",
  "	6-Seconds Test Optimized.",
  "Optimized for Success: Job-Specific Keywords in your Resume.",
  "	Achievement-Based & Metric Driven Accomplishments.",
  "	ATS Compatible Format.",
  "	Custom Tailored.",
  "	Unlimited Revisions.",
  "	60-Day Interview Guarantee.",
];

function BasicPackage({ levelType }) {
  return (
    <>
      <div className="font-semibold text-md">
        Basic Package {levelType === "Entry" && "(Entry Level)"}
        {levelType === "Mid" && "(Mid Career Level)"}
        {levelType === "Executive" && "(Executive Level)"}
      </div>
      <div className="text-left text-sm font-semibold mt-5 mb-5">
        Resume only
      </div>
      <div className="grid grid-cols-1 ">
        {items.map((item, index) => (
          <div className="flex items-center" key={index}>
            <img src={tick} className="w-4 h-4 mr-4 mb-3" alt="bullet" />
            <span className="text-sm">{item}</span>
          </div>
        ))}
      </div>
      <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
      <div className="flex flex-row justify-between">
        <p className="text-md font-semibold">Price </p>
        <p className="text-lg text-[#2BC210] font-extrabold"> $90</p>
      </div>
      <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold"></div>
    </>
  );
}

export default BasicPackage;
