import React from "react";
import subject from "../../../../../assets/svg_icons/subject.svg";
import topic from "../../../../../assets/svg_icons/newMessage.svg";
import upload from "../../../../../assets/svg_icons/upload.svg";

function HomeworkStep1({
  handleNextStep,
  formData,
  handleFileChange,
  handleInputChange,
  fileNames,
  uploadProgress,
  activeTab,
  handleIncrease,
  handleDecrease,
  handleSlidesInputIncrease,
  handleSlidesInputDecrease,
  handleSourceInputIncrease,
  handleSourceInputDecrease,
  handleTabClick,
}) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  return (
    <form className="w-full lg:px-5">
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Subject</label>
          <div className="relative">
            <input
              id="subject"
              type="text"
              placeholder="Enter subject here"
              required
              className={inputClassValues}
              value={formData.subject}
              onChange={handleInputChange}
            />
            <img
              src={subject}
              alt="subject"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Type of paper</label>
          <div className="relative">
            <input
              id="paperType"
              type="text"
              placeholder="Enter type of paper"
              required
              className={inputClassValues}
              value={formData.paperType}
              onChange={handleInputChange}
            />
            <img
              src={topic}
              alt="topic"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <label className="text-sm text-gray-800">Topic</label>
        <div className="relative">
          <input
            id="topic"
            type="text"
            placeholder="Enter your topic here"
            required
            className={inputClassValues}
            value={formData.topic}
            onChange={handleInputChange}
          />
          <img
            src={topic}
            alt="topic"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="text-sm text-gray-800">Project details</label>
        <textarea
          id="details"
          rows="4"
          placeholder="Mention your topic, main idea & requirements"
          className={inputClassValues}
          value={formData.details}
          onChange={handleInputChange}
        />
      </div>
      {/* Page / word count */}

      <div className="flex flex-col lg:flex-row gap-3 mt-4">
        <div className="w-full lg:w-1/2">
          {activeTab === "pages" && (
            <label className="text-sm text-gray-800">Page count</label>
          )}
          {activeTab === "words" && (
            <label className="text-sm text-gray-800">Word count</label>
          )}
          <div className="flex items-center space-x-2 ">
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleDecrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                -
              </div>
            </div>
            {activeTab === "pages" && (
              <div className="mr-0">
                <input
                  id="pageCount"
                  type="number"
                  min="0"
                  className={inputClassValues}
                  value={formData.pageCount}
                  onChange={handleInputChange}
                />
              </div>
            )}
            {activeTab === "words" && (
              <div className="mr-0">
                <input
                  id="wordCount"
                  type="number"
                  min="0"
                  className={inputClassValues}
                  value={formData.wordCount}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleIncrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                +
              </div>
            </div>
          </div>
        </div>
        {/* Page / word count tab here */}
        <div className="flex border border-[#2BC210] h-10 mt-10 mr-3">
          <div
            className={`border border-[#2BC210] hover:bg-[#439F46] hover:text-white p-2 justify-center items-center text-center text-sm cursor-pointer ${
              activeTab === "pages" ? "bg-[#439F46] text-white" : "bg-[#f5f5f5]"
            }`}
            onClick={() => handleTabClick("pages")}
          >
            Pages
          </div>
          <div
            className={`border border-[#2BC210] hover:bg-[#439F46] hover:text-white p-2 justify-center items-center text-center text-sm cursor-pointer ${
              activeTab === "words" ? "bg-[#439F46] text-white" : "bg-[#f5f5f5]"
            }`}
            onClick={() => handleTabClick("words")}
          >
            Words
          </div>
        </div>

        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Slides</label>
          <div className="flex items-center space-x-2 ">
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSlidesInputDecrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                -
              </div>
            </div>
            <div className="relative">
              <input
                id="powerPointSlides"
                type="number"
                min="0"
                placeholder="Number of slides"
                className={inputClassValues}
                value={formData.powerPointSlides}
                onChange={handleInputChange}
              />
            </div>
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSlidesInputIncrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="relative">
          <input
            type="file"
            onChange={handleFileChange}
            multiple
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <div className="flex flex-col items-center justify-center border-2 border-[#b2f3a7] border-dotted w-full bg-[#f5f5f5] p-6 rounded-lg">
            <div className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center">
              <img src={upload} alt="upload" className="w-4 h-4" />
            </div>
            <p className="text-center mb-3 mt-3 text-gray-900 text-sm">
              Attach files
            </p>
            {fileNames.length > 0 && (
              <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
                {fileNames.map((name, index) => (
                  <li key={index}>
                    {name}{" "}
                    {uploadProgress[name] !== undefined &&
                      `(${uploadProgress[name]}%)`}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col gap-3 mt-4">
        <div className="w-full lg:w-1/2 mt-2">
          <label className="text-sm text-gray-800">Paper format</label>
          <div className="relative w-full">
            <select
              className={inputClassValues}
              id="format"
              value={formData.format}
              onChange={handleInputChange}
              required
            >
              <option value="MLA">MLA</option>
              <option value="APA">APA</option>
              <option value="Chicago">Chicago</option>
              <option value="Harvard">Harvard</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-2 lg:ml-5">
          <label className="text-sm text-gray-800">Sources count</label>
          <div className="flex items-center space-x-2 ">
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSourceInputDecrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                -
              </div>
            </div>

            <input
              className={inputClassValues}
              type="number"
              min="0"
              placeholder="Sources count"
              id="noOfSources"
              value={formData.noOfSources}
              onChange={handleInputChange}
              required
            />

            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSourceInputIncrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={handleNextStep}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Next
      </button>
    </form>
  );
}

export default HomeworkStep1;
