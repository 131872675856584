import React from "react";
import ImageColumn from "./ImageColumn";
import brief from "../../assets/pngs/brief.png";
import constant from "../../assets/pngs/constant.png";
import expert from "../../assets/pngs/expert.png";
import TextColumn from "./TextColumn";

function StepRow() {
  return (
    <div className="flex mx-auto justify-center">
      <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
        {/* Row 1*/}
        <div className="p-6 ">
          <TextColumn
            stepNumber="01"
            stepTitle="Fill in The Brief"
            stepDescription="Describe your project, highlighting where you need help with and set deadline."
          />
        </div>
        <div className="p-6 ">
          <ImageColumn columnImage={brief} />
        </div>
        {/* Row 2*/}
        <div className="p-6 ">
          <ImageColumn columnImage={expert} />
        </div>
        <div className="p-6 ">
          <TextColumn
            stepNumber="02"
            stepTitle="Choose an Expert"
            stepDescription="Receive offers from our pool of experts and choose based on rating and reliability."
          />
        </div>
        {/* Row 3 */}

        <div className="p-6 ">
          <TextColumn
            stepNumber="03"
            stepTitle="Maintain constant communication"
            stepDescription="Get the professional assistance you need and regular updates on the project."
          />
        </div>
        <div className="p-6 ">
          <ImageColumn columnImage={constant} />
        </div>
      </div>
    </div>
  );
}

export default StepRow;
