import React from "react";
import suptallogo from "../../assets/logos/Hubproof.svg";
import rate from "../../assets/svg_icons/star.svg";
function FirstColumn() {
  //Temporarily display 5 stars
  const stars = [];

  for (let i = 0; i < 5; i++) {
    stars.push(<img key={i} src={rate} alt="star" className="w-6 h-6 star " />);
  }
  return (
    <div className="w-full md:w-1/4 px-6 py-4">
      <h1 className="text-3xl font-bold mb-4">Excellent</h1>
      <h4 className="font-bold mb-5">
        Based on <span> 3504 Reviews</span>
      </h4>
      <img src={suptallogo} alt="logo" className="mb-5 logo" />

      <div className="flex space-x-1 mb-3">{stars}</div>
    </div>
  );
}

export default FirstColumn;
