import React from "react";

import facebook from "../../assets/icons/facebook.png";
import { FacebookAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../firebase";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signingSuccess } from "../../redux/user/userSlice";
import { baseURL } from "../../config";

function Facebook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFacebookClick = async () => {
    try {
      console.log("testing facebook");
      const provider = new FacebookAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithPopup(auth, provider);
      const res = await fetch(`${baseURL}/auth/facebook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: result.user.email,
          name: result.user.displayName,
          photoUrl: result.user.photoURL,
        }),
      });
      const data = await res.json();
      localStorage.setItem("accessToken", data.accessToken);
      dispatch(signingSuccess(data));
      navigate("/dashboard");
    } catch (error) {
      console.log("Could not connect to Facebook", error);
    }
  };
  return (
    <button
      className="icon_button mr-3"
      type="button"
      onClick={handleFacebookClick}
    >
      <img src={facebook} alt="facebook-signin" className=" w-4 h-4" />
    </button>
  );
}

export default Facebook;
