import React from "react";

function PackageDetails() {
  return (
    <div className="mt-4 bg-[#FFFFFF] rounded-[10px] border border-[#E4EFE4] p-5 ">
      Enter selected packages here
    </div>
  );
}

export default PackageDetails;
