import React from "react";
import rate from "../../assets/svg_icons/star.svg";

function SingleProof({
  rating,
  ratingTitle,
  ratingDescription,
  raterName,
  raterDuration,
}) {
  const stars = [];

  for (let i = 0; i < rating; i++) {
    stars.push(<img key={i} src={rate} alt="star" className="w-6 h-6 star " />);
  }

  return (
    <div className="card proof-card px-4 py-6 rounded-xl shadow-md mr-3">
      <div className="flex space-x-1 mb-3">{stars}</div>
      <h3 className="font-bold mb-2">{ratingTitle}</h3>
      <h4 className="mb-3 text-sm">{ratingDescription}</h4>
      <h4 className="font-bold">{raterName}</h4>
      <h4 className="text-sm">{raterDuration}</h4>
    </div>
  );
}

export default SingleProof;
