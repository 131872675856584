import React from "react";
import apple from "../../assets/icons/applesignin.png";

import Oauth from "./Oauth";
import Facebook from "./Facebook";

function SocialAuthentication() {
  return (
    <div className="flex flex-row justify-center items-center">
      <Oauth />
      <div className="icon_button mr-3">
        <img src={apple} alt="apple-signin" className=" w-4 h-4" />
      </div>
      <Facebook />
    </div>
  );
}

export default SocialAuthentication;
