import React, { useRef, useState } from "react";
import SingleProof from "./SingleProof";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nextArrow from "../../assets/icons/arrowright.svg";
import prevArrow from "../../assets/icons/arrowleft.svg";
import ArrowButton from "./ArrowButton";

function SecondColumn() {
  const sliderRef = useRef(null);
  const [isPrevActive, setPrevIsActive] = useState(false);
  const [isNextActive, setNextIsActive] = useState(false);

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
    !isPrevActive ? setPrevIsActive(!isPrevActive) : setPrevIsActive(true);
    setNextIsActive(false);
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
    !isNextActive ? setNextIsActive(!isNextActive) : setNextIsActive(true);
    setPrevIsActive(false);
  };
  const data = [
    {
      rating: 5,
      ratingTitle: "Superior Experience",
      ratingDescription:
        "These alternatives to the classic Lorem Ipsum texts are often amusing and tell short.",
      raterName: "John Doe",
      raterDuration: "4 days ago",
    },
    {
      rating: 4,
      ratingTitle: "Great Experience",
      ratingDescription:
        "These alternatives to the classic Lorem Ipsum texts are often amusing and tell short.",
      raterName: "Jane Doe",
      raterDuration: "2 days ago",
    },
    {
      rating: 3,
      ratingTitle: "Okay Experience",
      ratingDescription:
        "These alternatives to the classic Lorem Ipsum texts are often amusing and tell short.",
      raterName: "Anne Smith",
      raterDuration: "2 hours ago",
    },
    {
      rating: 5,
      ratingTitle: "Best Experience",
      ratingDescription:
        "These alternatives to the classic Lorem Ipsum texts are often amusing and tell short.",
      raterName: "Alan Walker",
      raterDuration: "6 hours ago",
    },
  ];
  const settings = {
    customPaging: function () {
      return (
        <div className="mt-5 flex justify-between">
          <button onClick={handlePrevClick}>
            <img src={prevArrow} alt="Previous" />
          </button>
          <button onClick={handleNextClick}>
            <img src={nextArrow} alt="Next" />
          </button>
        </div>
      );
    },
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full md:w-3/4 p-4 relative">
      {/* <div className="flex flex-col md:flex-row justify-center md:justify-start space-y-4 md:space-y-0 md:space-x-4 relative"> */}
      {/* <div className="flex justify-center md:justify-start space-y-4 md:space-y-0 md:space-x-4 relative "> */}
      <div className=" flex flex-col justify-center md:justify-start space-y-4 md:space-y-0 md:space-x-5 relative transform md:-translate-y-20">
        <Slider ref={sliderRef} {...settings}>
          {data.map((datum, index) => (
            <SingleProof
              key={index}
              rating={datum.rating}
              ratingTitle={datum.ratingTitle}
              ratingDescription={datum.ratingDescription}
              raterName={datum.raterName}
              raterDuration={datum.raterDuration}
            />
          ))}
        </Slider>
        <div className="mt-5 flex flex-row justify-center items-center">
          {isPrevActive ? (
            <ArrowButton
              onClick={handlePrevClick}
              src={prevArrow}
              displayCircle={true}
              alt="previous"
              className="active-previous-arrow"
            />
          ) : (
            <ArrowButton
              onClick={handlePrevClick}
              src={prevArrow}
              displayCircle={false}
              alt="previous"
              className="previous-arrow"
            />
          )}
          {isNextActive ? (
            <ArrowButton
              onClick={handleNextClick}
              src={nextArrow}
              displayCircle={true}
              alt="next"
              className="active-next-arrow"
            />
          ) : (
            <ArrowButton
              onClick={handleNextClick}
              src={nextArrow}
              displayCircle={false}
              alt="next"
              className="next-arrow"
            />
          )}
          {/* <button
            className="slider-button next-button mt-5"
            onClick={handleNextClick}
          >
            <img src={nextArrow} alt="Next" />
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default SecondColumn;
