import React from "react";
import Layout from "../../components/Layout";
import TheFaqs from "../../components/TheFaqs/TheFaqs";
import SocialProof from "../../components/SocialProof/SocialProof";
import Experts from "../../components/Experts/Experts";
import Skills from "../../components/Skills/Skills";
import WhySuptal from "../../components/WhySuptal/WhySuptal";
import HowWeWork from "../../components/Steps/HowWeWork";
import Hero from "../../components/Hero/Hero";

function Home() {
  return (
    <Layout>
      <Hero />
      <HowWeWork />
      <WhySuptal />
      <Skills />
      <Experts />
      <SocialProof />
      <TheFaqs />
    </Layout>
  );
}

export default Home;
