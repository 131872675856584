import React from "react";
import DashboardCard from "../../Dashboard/DashboardCard";
import star from "../../../assets/svg_icons/blackStar.svg";
import likeIcon from "../../../assets/svg_icons/like.svg";
import HorizontalDivider from "../../Divider/HorizontalDivider";

function ProjectBrief() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-[3fr_2fr] gap-5 mt-4">
      <div className="mt-4 bg-[#FFFFFF] rounded-[10px] border border-[#E4EFE4] p-5 ">
        {/* Topic */}
        <div className="w-full bg-[#FFFFFF] rounded-[10px] mb-5 overflow-hidden border border-[#E4E4E5] shadow shadow-[#D4DFD41F] items-start ">
          <div className=" p-2 ps-3 text-left">Topic</div>
          <HorizontalDivider />
          <div className="p-4">
            <div className="flex flex-row justify-between">
              {/* The topic */}
              <span className="font-bold text-lg">Gun Violence in America</span>
              {/* The progress */}
              <button
                type="button"
                className="px-6 py-1 text-sm text-[#439F46] border border-[#439F46] bg-[#FFFFFF] cursor-pointer font-semibold rounded-[6px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] "
              >
                In progress
              </button>
            </div>
          </div>
        </div>
        {/* Project Brief */}
        <div className="w-full bg-[#FFFFFF] rounded-[10px] mb-5 overflow-hidden border border-[#E4E4E5] shadow shadow-[#D4DFD41F] items-start ">
          <div className=" p-2 ps-3  text-left">Project brief</div>
          <HorizontalDivider />
          <div className="p-4">
            <div className="text-left justify-normal text-sm ">
              There is now an abundance of readable dummy texts. These are
              usually used when a text is required purely to fill a space. These
              alternatives to the classic Lorem Ipsum texts are often amusing
              and tell short, funny or nonsensical stories.
            </div>
          </div>
        </div>
      </div>
      {/* Rating , Recommend to other and reviews */}
      <div className="mt-4 bg-[#FFFFFF] rounded-[10px] border border-[#E4EFE4] p-5 ">
        {/* Rating */}
        <div className="relative p-3 mt-2 mb-3 rounded-[20px] border-[#484B48]">
          <div className="absolute inset-0 bg-[#484B48] opacity-[0.05] rounded-[10px] pointer-events-none"></div>
          <div className="relative">
            <div className="flex flex-row gap-2 justify-between ">
              <div className="text-sm">Rating</div>
              <div className="inline-flex flex-row">
                <img src={star} alt="rating" className="h-3 mr-2 my-auto" />
                <span className="text-md font-semibold">4.9 /5</span>
              </div>
            </div>
          </div>
        </div>
        {/* Recommendation */}
        <div className="relative p-3 mt-2 mb-3 rounded-[20px] border-[#484B48]">
          <div className="absolute inset-0 bg-[#484B48] opacity-[0.05] rounded-[10px] pointer-events-none"></div>
          <div className="relative">
            <div className="flex flex-row gap-2 justify-between ">
              <div className="text-sm">Recommendation to others</div>
              <div className="inline-flex flex-row">
                <div className="bg-[#ffffff] border border-[#EDF5EE] rounded-full p-3">
                  <img src={likeIcon} alt="favorite" className=" w-4 h-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reviews */}
        <DashboardCard
          cardTitle={<div className="text-left font-semibold"> Review </div>}
          cardContent={
            <div className="text-left justify-normal text-sm mt-3">
              Great job. Delivered or time showed so much professional. Thank
              you so much!
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ProjectBrief;
