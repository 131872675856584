import React from "react";
import star from "../../../assets/svg_icons/blackStar.svg";
import chat from "../../../assets/svg_icons/green-chat.svg";
import DashboardCard from "../../../components/Dashboard/DashboardCard";

function SingleBid({ writer }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[1fr_2fr_1fr] gap-5 mt-4">
      {/* 1: Profile card - Smaller columns */}
      <div className="flex flex-col">
        <div className="rounded-[10px] bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F] p-5">
          <div className="flex flex-col">
            <div className="relative w-30 h-30 mt-4 mx-auto">
              {/* Writer's Photo */}
              <img
                className="w-full h-full rounded-full object-cover"
                src={writer.profilePhoto}
                alt="Profile"
              />
              {/* Status Indicator */}
              <div
                className={`absolute bottom-4 -right-1 w-5 h-5 rounded-full border-2 ${
                  writer.isActive
                    ? "bg-[#439F46] border-[#E9E9E9]"
                    : "bg-[#808080] border-[#E9E9E9]"
                }`}
              ></div>
            </div>

            {/* Writer's name */}
            <div className="font-bold text-lg mt-2 text-center">
              {writer.writerName}
            </div>
          </div>
        </div>
        {/* Reviews Card */}
        <div className="rounded-[10px] mt-5 mb-2 bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F] p-2">
          <div className="relative">
            {/* Writer's star rating */}
            <div className="flex flex-row">
              <span>
                <img src={star} alt="rating" className="mr-1 " />
              </span>
              <span className="mr-1 font-semibold">{writer.rating}</span>
              <span>({writer.reviews} reviews)</span>
            </div>
          </div>
        </div>

        {/* Writer's Expert level */}
        <div className="rounded-[10px] bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F] p-2 mb-2">
          <div className="flex flex-row gap-2 justify-between ">
            <span className="text-sm">Level :</span>
            {/*Enter List of levels */}
            <div className="text-sm font-semibold ">{writer.expertLevel}</div>
          </div>
        </div>
        {/* Orders in progress */}
        <div className="rounded-[10px] bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F] p-2">
          <div className="flex flex-row gap-2 justify-between ">
            <span className="text-md">Orders in progress :</span>
            <span>{writer.ordersInProgress}</span>
          </div>
        </div>
      </div>
      {/* 2: Additional Information about the writer - Larger middle column */}
      <div className="rounded-[10px] bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F] p-5">
        {writer.bidInformation}
      </div>
      {/* 3: Price of the job, accept or deny offer and chat - Smaller columns */}
      <div className="flex flex-col">
        {/* Price of job */}
        <DashboardCard
          cardTitle={
            <div className="flex flex-row justify-between gap-2">
              <span>Price</span>
              <span className="font-bold">${writer.price} (USD)</span>
            </div>
          }
          cardContent={
            <div className="flex flex-col">
              {/* Accept Offer */}
              <button
                type="button"
                className="mt-5 p-3 mx-auto flex justify-center items-center text-center border border-[#AFD1B0] bg-[#0E120E] text-white cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
              >
                Accept offer
              </button>
              {/* Reject Offer */}
              <div className="mt-5 p-3 mx-auto flex justify-center items-center text-center border-b border-[#ff0000] bg-[#FFFFFF] cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105">
                Decline offer
              </div>
            </div>
          }
        />
        {/* Chat Function */}
        <button
          type="button"
          className="relative mt-8 py-3 px-10 mx-auto flex  text-center text-[#439F46] border border-[#439F46] bg-[#FFFFFF] cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
        >
          <img src={chat} className="w-6 mr-1" alt="chat" />
          Chat
          {/* Chat Count Indicator */}
          <div className="absolute top-0 right-0 w-4 h-4 border border-[#439F46] bg-[#F3F3F3] text-xs  text-[#439F46] flex items-center justify-center rounded-full -translate-x-1/2 -translate-y-1/2">
            {writer.chatsCount}
          </div>
        </button>
      </div>
    </div>
  );
}

export default SingleBid;
