import React from "react";
import StructureCheckCard from "../../../../../components/StructureCheckCard";
import { baseURL } from "../../../../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Step2({ handleNextStep, formData, handleInputChange }) {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Resume:", formData);

    try {
      const res = await fetch(`${baseURL}/service/proofreading/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          ...formData,
          userRef: currentUser._id,
        }),
      });
      console.log(currentUser);

      const data = await res.json();

      console.log("Backend Response: ", data);
      if (data.success === false) {
        console.log("Error uploading data:", data.message);
      }

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  const packageAddonsList = [
    {
      id: "structureCheck",
      title: "Structure check",
      itemsList: [
        "Thorough organization to include all required elements based on the provided instructions.",
        "Evaluation of each paragraph to ensure alignment with the document's primary objective.",
        "Explanation of acronyms for clarity and understanding.",
        "Review of paragraph organization and focus.",
        "Ensuring seamless continuity between paragraphs.",
        "Elimination of redundant and repetitive information.",
        "Prevention of sentence splicing.",
        "Attention to the content and impact of titles and headings.",
        "Verification of figures, tables, and paragraph structures for proper numeration and clarity",
      ],
      tagLine: "* $3 per page ($ 0.0109 per word) ",
    },
    {
      id: "clarityCheck",
      title: "Clarity check",
      itemsList: [
        "Ensure your work presents a clear and logical narrative.",
        "	Clarify any ambiguous sentences.",
        "	Check for clear presentation of concepts and ideas.",
        "	Streamline overly verbose sentences.",
        "	Provide feedback on the logic of your arguments.",
        "Identify and highlight contradictions in the text.",
        "	Make sentences easier to follow and comprehend.",
      ],
      tagLine: "* $3 per page ($ 0.0109 per word) ",
    },
    {
      id: "documentFormatting",
      title: "Document Formatting and Editing",
      itemsList: [
        "	Create a professional cover page.",
        "	Generate a dynamic table of contents, if necessary",
        "	Insert lists of tables and figures, if required.",
        "	Ensure consistent paragraph formatting throughout the document.",
        "	Maintain uniformity in font style and usage.",
        "	Add page numbers.",
        "	Adjust margins",
        "	Ensure consistent line spacing.",
        "	Maintain consistent font and indentation styles.",
        "	Set up running heads and page numbers, if applicable.",
        "	Check headings for consistency in formatting.",
        "	Format the title page correctly.",
        "	Format the abstract and keywords, if required.",
        "	Format footnotes as needed.",
        "	Ensure tables are consistently formatted.",
      ],
      tagLine: "* $1 per page ",
    },
    {
      id: "citationCheck",
      title: "Citation Check and Editing",
      itemsList: [
        "	Verify that your in-text citations and references adhere to your style guide's requirements.",
        "	Offer feedback on incomplete or inaccurate in-text citations and reference entries.",
        "	Cross-check in-text citations with the references list to identify inconsistencies and missing sources.",
        "	Ensure that all sources cited in the text are included in the references list, and vice versa.",
        "	Check for proper formatting of in-text citations and references according to the specified style guide.",
        "	Provide suggestions for improving the clarity and accuracy of citations and references.",
        "	Verify the accuracy of publication details in the references list, such as authors' names, publication dates, titles, and page numbers.",
        "	Offer guidance on how to integrate citations smoothly into the text for better readability.",
        "	Ensure that quotations are properly cited and formatted according to the style guide.",
        "	Check for proper use of et al. for multiple authors in in-text citations.",
        "	Provide examples and explanations of correct citation formats as needed.",
        "	Offer suggestions for improving the consistency and coherence of citations throughout the document.",
      ],
      tagLine: "* $2 per source ",
    },
    {
      id: "plagiarismCheck",
      title: "Plagiarism & AI Check",
      itemsList: ["	Turnitin Report without database storage"],
      tagLine: "* $5 per report ",
    },
    {
      id: "plagiarismEditing",
      title: "Plagiarism & Humanising-AI Editing",
      itemsList: [
        "Paraphrasing of plagiarised content",
        "	Enhance emotional depth and empathy in the text.",
        "	Incorporate cultural nuances and context.",
        "	Add idiomatic expressions and colloquialisms.",
        "	Ensure the text is relatable and resonates with the audience.",
        "	Focus on creating a natural flow and conversational tone.",
        "	Pay attention to the subtleties of language and human interaction.",
        "	Avoid overly formal or robotic language.",
        "	Use storytelling techniques to engage readers.",
        "	Personalize the content to reflect the brand's voice and values.",
        "	Infuse the text with creativity and originality.",
      ],
      tagLine: "* $0.025 per word (will be billed later) ",
    },
  ];
  return (
    <form className="w-full lg:px-5" onSubmit={handleSubmit}>
      <div className="flex justify-between items-center sm:gap-5 mb-5">
        <label className="text-lg text-gray-900 font-bold text-left">
          Popular Add-On Services
        </label>
        <div className="bg-[#F5FFF6] shadow-md px-8 py-3 rounded-[20px] border border-[#2BC210] text-2xl text-[#2BC210] font-extrabold">
          Total : $55
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
        {packageAddonsList.map((addon, index) => (
          <StructureCheckCard
            handleInputChange={handleInputChange}
            formData={formData}
            key={index}
            cardLabel={addon.title}
            items={addon.itemsList}
            tagLine={addon.tagLine}
            field={addon.id}
          />
        ))}
      </div>
      <div className="mt-8  mb-3">
        <button
          type="submit"
          className=" green-button mx-auto lg:w-1/4 sm:w-3/4 w-1/2  p-8 flex justify-center items-center text-center"
          onClick={handleNextStep}
        >
          Submit & Pay
        </button>
      </div>
    </form>
  );
}

export default Step2;
