import React from "react";

function ResolutionCenter() {
  const inputclassValues =
    "w-full mb-2 lg:w-1/2 bg-[#f5f5f5] text-gray-900  text-left mt-2 p-3 cursor-pointer rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1  focus:ring-[#2BC210] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105";
  return (
    <form className="mt-5  ps-5">
      <div className="mt-4 bg-[#FFFFFF] rounded-[10px] border border-[#E4EFE4] p-5 ">
        <div className="mt-2  mb-2 text-[30px] font-bold text-left">
          Resolution center
        </div>
        <div className="text-md font-semibold text-left mb-2">
          Here is where clients and writers try to resolve order issues
        </div>
        <div className="flex flex-col">
          {/* if client display the following inputs */}
          {/* Partial refund */}
          <input
            type="button"
            className={inputclassValues}
            id="partialRefund"
            value="Offer partial refund"
          />
          {/* Cancel order */}
          <input
            type="button"
            className={inputclassValues}
            id="cancelOrder"
            value="Cancel order"
          />
        </div>
      </div>
    </form>
  );
}

export default ResolutionCenter;
