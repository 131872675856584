import React from "react";

function Reasons({ reasonImage, reasonTitle, reasonDescription }) {
  return (
    <div className="p-8 text-center  transform  transition duration-500 hover:-translate-y-2 after:transition after:ease-[ease] after:duration-200 cursor-pointer ">
      <div className="flex items-center justify-center p-8 ">
        <img
          src={reasonImage}
          alt=""
          className="w-15 h-15 bg-white  shadow-lg rounded-full p-3 "
        />
      </div>
      <h4 className="font-bold text-lg mb-4">{reasonTitle}</h4>
      <p className="text-sm leading-6 text-gray-600">{reasonDescription}</p>
    </div>
  );
}

export default Reasons;
