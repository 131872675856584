import React from "react";
import { useSelector } from "react-redux";
import ClientBidding from "../bidding/ClientBidding";

function ClientDashboard() {
  useSelector((state) => state.user);
  return (
    <>
      <div>
        <ClientBidding />
      </div>
    </>
  );
}

export default ClientDashboard;
