import React from "react";

function Ratings() {
  return (
    <div className="flex gap-1">
      <h2 className="">Ratings: </h2>
      <h3 className="">
        <span className="font-bold"> 4.9/5 </span>100{" "}
      </h3>
    </div>
  );
}

export default Ratings;
