import React from "react";

function FooterLinks() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-20 py-12 footer-top">
      {/*---- First Column----*/}
      <div className="footer-links">
        <h4>Services</h4>
        <ul>
          <li>
            <a href="/">Services</a>
          </li>
          <li>
            <a href="/">Reviews</a>
          </li>
          <li>
            <a href="/">Experts</a>
          </li>
          <li>
            <a href="/">Knowledge base</a>
          </li>
          <li>
            <i className="bx bx-chevron-right"></i>
            <a href="/">Apply as Freelancer</a>
          </li>
          <li>
            <a href="/">Hire top talent</a>
          </li>
        </ul>
      </div>
      {/*---- Second Column----*/}
      <div className="footer-links">
        <h4>About</h4>
        <ul>
          <li>
            <a href="/">Careers</a>
          </li>
          <li>
            <a href="/">Patnership</a>
          </li>
          <li>
            <a href="/">Privacy policy</a>
          </li>
          <li>
            <a href="/">Terms of service</a>
          </li>
          <li>
            <a href="/">Honor code</a>
          </li>
        </ul>
      </div>

      {/*-----  Third Column-----*/}
      <div className="footer-links">
        <h4>Support & Education</h4>
        <ul>
          <li>
            <a href="/">Help & Support</a>
          </li>
          <li>
            <a href="/">Trust & Safety</a>
          </li>
          <li>
            <a href="/">Hub Guides</a>
          </li>
        </ul>
      </div>

      {/*----    Fourth Column ------*/}
      <div className="footer-links">
        <h4>Community</h4>
        <ul>
          <li>
            <a href="/">Reviews</a>
          </li>
          <li>
            <a href="/">Knowledge Base</a>
          </li>
          <li>
            <a href="/">Affiliates</a>
          </li>
          <li>
            <a href="/">Invite a friend</a>
          </li>
          <li>
            <a href="/">Become an Expert</a>
          </li>
          <li>
            <a href="/">Forum</a>
          </li>
          <li>
            <a href="/">Youtube</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterLinks;
