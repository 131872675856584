import React from "react";
import SingleSkill from "./SingleSkill";
import homeWorkHelp from "../../assets/pngs/skills/homework-help.png";
import editing from "../../assets/pngs/skills/editing.png";
import resume from "../../assets/pngs/skills/resume.png";
import citationediting from "../../assets/pngs/skills/citation-editing.png";
import citationgeneration from "../../assets/pngs/skills/citation-generator.png";
import turnitin from "../../assets/pngs/skills/turnitin-report.png";

function Skills() {
  return (
    <section className="bg-[ #f5f5f5] section">
      <div className="container mx-auto px-3 lg:px-10 py-12">
        <div className="text-center section-title mb-8">
          <h2 className="font-bold text-3xl mb-10">
            Leverage World Class Skills
          </h2>
          <h6 className="text-xl text-gray-600">
            We are ready to tackle your project
          </h6>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <SingleSkill
            skillImage={homeWorkHelp}
            skillLink=""
            skillTitle="Homework help"
            skillDescription="Description for Homework help."
          />
          <SingleSkill
            skillImage={editing}
            skillLink=""
            skillTitle="Editing & Proofreading"
            skillDescription="Description for Editing & Proofreading."
          />
          <SingleSkill
            skillImage={resume}
            skillLink=""
            skillTitle="Resume Writing"
            skillDescription="Description for Resume Writing."
          />
          <SingleSkill
            skillImage={citationediting}
            skillLink=""
            skillTitle="Citation Editing"
            skillDescription="Description for Citation Editing."
          />
          <SingleSkill
            skillImage={citationgeneration}
            skillLink=""
            skillTitle="Citation Generator"
            skillDescription="Description for Citation Generator."
          />
          <SingleSkill
            skillImage={turnitin}
            skillLink=""
            skillTitle="Turnitin Report"
            skillDescription="Description for Turnitin Report."
          />
        </div>
      </div>
    </section>
  );
}

export default Skills;
