import React from "react";
import dollar from "../../../../../assets/svg_icons/dollar.svg";

function HomeworkStep2({ handleNextStep, formData, handleInputChange }) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  return (
    <form className="mt-5 w-full px-5">
      {/* Budget */}
      <div className="w-full mb-8">
        <h4 className="text-left text-dark font-semibold">Budget</h4>
        <div className="mt-2">
          <label className="text-sm text-gray-800">I am looking to spend</label>
          <div className="relative">
            <input
              id="price"
              className={inputClassValues}
              type="number"
              placeholder="Enter budget"
              value={formData.orderDetails.price}
              onChange={handleInputChange}
            />
            <img
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
              src={dollar}
              alt="budget"
            />
          </div>
          <p className="text-sm text-gray-800 text-right mt-1">
            Average range for this service $80 - $800
          </p>
        </div>
      </div>

      {/* Deadline */}
      <div className="w-full">
        <h4 className="text-left text-dark font-semibold">Deadline</h4>
        <div className="mt-2">
          <label className="text-sm text-gray-800">Let's talk timing</label>
          <div className="relative">
            <input
              id="deadline"
              className={inputClassValues}
              type="datetime-local"
              placeholder="Enter Deadline"
              value={formData.deadline}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={handleNextStep}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Review your brief
      </button>
    </form>
  );
}

export default HomeworkStep2;
