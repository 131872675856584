import React, { useState } from "react";
import HomeworkStepper from "../HomeworkHelp/HomeworkStepper";
import backArrow from "../../../../assets/svg_icons/arrow_left_1.svg";
import { devUrl } from "../../../../config";
import { Link } from "react-router-dom";
import Step1 from "./Step1";

function CitationEditingService() {
  const steps = [
    {
      stepNumber: "1",
      stepTitle: "Project brief",
    },
  ];
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [formData, setFormData] = useState({
    details: "",
    files: [],
    format: "MLA",
    noOfSources: 0,
    orderDetails: {
      price: 0,
    },
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
  };
  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const fileUrls = [];
    const names = selectedFiles.map((file) => file.name);
    console.log("formData before files,", formData);
    setFileNames((prevNames) => [...prevNames, ...names]);
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => formData.append("files", file));

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${devUrl}/uploads/upload-files`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          // Assuming `names` array matches the selected files order
          selectedFiles.forEach((file) => {
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          });
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.fileUrls) {
            fileUrls.push(...response.fileUrls);

            // Update formData with the file URLs
            setFormData((prevFormData) => ({
              ...prevFormData,
              files: [...prevFormData.files, ...fileUrls],
            }));

            console.log("Files uploaded successfully:", fileUrls);
          } else {
            console.error("fileUrls not found in response:", response);
          }
        } else {
          console.error("Error uploading files:", xhr.responseText);
        }
      };

      xhr.onerror = () => {
        console.error("Error uploading files");
      };

      xhr.send(formData);
      console.log("Formdata after files ,", formData);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <div className="container mx-auto bg-[#f5f5f5] p-8">
      <div className="flex flex-row justify-start items-center mb-8">
        <img src={backArrow} alt="Back" className="w-6 mr-3" />
        <Link to="/selectservices" className="text-green-600">
          Back
        </Link>
      </div>

      <div className="flex justify-center mb-8">
        {steps.map((step) => (
          <div
            key={step.stepNumber}
            className={`w-20 h-2 bg-[#439F46] rounded-full mr-2 cursor-pointer ${
              activeStep.stepNumber !== step.stepNumber ? "opacity-20" : ""
            }`}
            onClick={() => setActiveStep(step)}
          />
        ))}
      </div>

      <div className="text-center mb-8">
        <h1>Citation Editing</h1>
      </div>

      <div className="flex w-full min-h-screen">
        <div className="hidden lg:block lg:w-1/4 bg-[#EEF5EF] p-8 rounded-[40px] shadow-md h-90 mt-12">
          <HomeworkStepper
            activeStep={activeStep}
            steps={steps}
            setActiveStep={setActiveStep}
          />
        </div>

        <div className="lg:w-3/4 w-full p-8">
          {activeStep.stepNumber === "1" && (
            <Step1
              formData={formData}
              handleInputChange={handleInputChange}
              handleFileChange={handleFileChange}
              fileNames={fileNames}
              uploadProgress={uploadProgress}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CitationEditingService;
