import React from "react";
import herobg from "../../assets/background/hero-bg.png";
import heroImage from "../../assets/background/hero-image.png";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div
      id="hero"
      className="flex flex-col lg:flex-row items-center justify-center bg-[#f2fbf3] relative  "
    >
      <div className="flex flex-col  justify-center items-center  lg:w-1/2 ">
        <div className=" mr-5 overflow-hidden mt-20 ">
          <div className="lg:ps-20 mx-auto px-3">
            <h1 className="text-3xl md:text-6xl font-extrabold text-black">
              GET 24/7 EXPERT <br />
              <span className="text- green-0">SOLUTIONS</span>
            </h1>
            <div className="max-w-lg mb-16">
              <p className="py-4  text-xl">
                30 Mins Time-Around. Tailored Solutions designed to help you
                learn,  hone your skills  and excel.
              </p>
            </div>
            <Link
              to={"/auth"}
              className=" text-white bg-green-0 text-md font-bold px-4 py-3  rounded-md [transition:0.3s] hover:bg-green hover:text-white"
            >
              Hire Top Talent
            </Link>
            <div className="mt-4"> </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex w-full lg:w-2/3 flex-col items-center top-0 right-0 inset-0 -z-0">
        <div className="relative ">
          <img src={herobg} alt="HeroImage 1" />
          <div className="absolute inset-0 right-0 top-0 -mt-6 mr-0 flex items-center justify-center">
            <div className="mt-5 hero-rating"></div>
            <img
              src={heroImage}
              alt="heroImage"
              className="pb-4 pt-0   -mr-20 h-full w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
