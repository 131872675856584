import React from "react";
import "./css/footer.css";
import FooterLinks from "./FooterLinks";
import CopyRight from "./CopyRight";
import PaymentSection from "./PaymentSection";
import AppButtons from "./AppButtons";

function Footer() {
  return (
    <footer id="footer">
      <FooterLinks />
      <PaymentSection />
      <AppButtons />
      <CopyRight />
    </footer>
  );
}

export default Footer;
