import React from "react";
import expertLevel1 from "../../assets/pngs/expert-levels/level1.png";
import expertLevel2 from "../../assets/pngs/expert-levels/level2.png";
import expertLevel3 from "../../assets/pngs/expert-levels/level3.png";
import expertLevel4 from "../../assets/pngs/expert-levels/level4.png";

function ExpertProfile({ expertPhoto, expertName, expertLevel, e }) {
  return (
    <div className="flex items-center">
      {/* First Column */}
      <div className="flex-shrink-0 mr-1">
        {/* Profile Photo */}
        <img
          className="w-12 h-12 rounded-full img-fluid"
          src={expertPhoto}
          alt="Profile"
        />
      </div>

      {/* Profile Name */}
      <div className="flex-1 mr-3">
        <p
          className="font-semibold overflow-hidden whitespace-nowrap "
          style={{ fontSize: "13px" }}
        >
          {expertName}
        </p>
      </div>

      {/* Second Column */}
      <div className="mr-auto flex">
        {/* Six Equal Columns */}
        <div className="flex-1 mr-2">
          <p className="font-semibold  " style={{ fontSize: "13px" }}>
            Level
          </p>
        </div>
        <div className="col-span-1 expert-levels">
          <img
            alt="expert"
            src={expertLevel1}
            className="image-fluid"
            style={{
              background: expertLevel === "level1" ? " #63B066" : "#f2fbf3",
              padding: "5px",
              borderRadius: "50% ",
            }}
          />
        </div>

        <div className="col-span-1">
          <img
            alt="expert"
            src={expertLevel2}
            className="image-fluid"
            style={{
              background: expertLevel === "level2" ? " #63B066" : "#f2fbf3",
              padding: "5px",
              borderRadius: "50% ",
            }}
          />
        </div>

        <div className="col-span-1">
          <img
            alt="expert"
            src={expertLevel3}
            className="image-fluid"
            style={{
              background: expertLevel === "level3" ? " #63B066" : "#f2fbf3",
              padding: "5px",
              borderRadius: "50% ",
            }}
          />
        </div>

        <div className="col-span-1">
          <img
            alt="expert"
            src={expertLevel4}
            className="image-fluid"
            style={{
              background: expertLevel === "level4" ? " #63B066" : "#f2fbf3",
              padding: "5px",
              borderRadius: "50% ",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpertProfile;
