import React from "react";
import DashboardCard from "../Dashboard/DashboardCard";
import writerPhoto from "../../assets/pngs/experts/expert2.png";
import star from "../../assets/svg_icons/blackStar.svg";

function EditorInformationCard() {
  const isActive = true;

  return (
    <DashboardCard
      cardTitle={
        <div className="flex flex-row justify-between">
          {/* Photo, Rating & Name */}
          <div className="flex flex-row gap-4">
            <div className="relative w-15 h-15  mx-auto">
              {/* Writer's Photo */}
              <img
                className="w-full h-full rounded-full object-cover"
                src={writerPhoto}
                alt="Profile"
              />
              {/* Status Indicator */}
              <div
                className={`absolute bottom-2 -right-1 w-4 h-4 rounded-full border-2 ${
                  isActive
                    ? "bg-[#439F46] border-[#E9E9E9]"
                    : "bg-[#808080] border-[#E9E9E9]"
                }`}
              ></div>
            </div>

            {/* Writer's name */}
            <div className="flex flex-col">
              <div className="text-sm text-left">
                editor Id: <span className="font-semibold"> #165034 </span>
              </div>
              <div className="flex flex-row text-sm font-bold mt-2 ">
                <span>
                  <img src={star} alt="rating" className="mr-1  h-3 my-1" />
                </span>
                <span className="mr-1 font-semibold ">4</span>
                <span className="font-light">(20)</span>
              </div>
            </div>
          </div>
        </div>
      }
      cardContent={
        <div className="flex flex-col">
          {/* editor Information */}
          {/* {editorInformation.map((editor) => (
            <div className="flex flex-row gap-2 justify-between mb-8 ">
              <span className="text-sm">{editor.title}</span>
              <span className="text-sm font-semibold">{editor.subTitle}</span>
            </div>
          ))} */}
          {/* View profile button */}
          <div className="mx-auto">
            <button
              type="button"
              className="px-6 py-2 text-sm mt-8 w-full text-[#0E120E] border border-[#0E120E] bg-[#FFFFFF] cursor-pointer font-semibold rounded-[6px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105 hover:bg-[#E9E9E9] "
            >
              View profile
            </button>
          </div>
        </div>
      }
    />
  );
}

export default EditorInformationCard;
