import React from "react";
import "./css/social-proof.css";

import FirstColumn from "./FirstColumn";
import SecondColumn from "./SecondColumn";

function SocialProof() {
  return (
    <section className="section section-bg" id="social-proof">
      <div className="container px-3 lg:px-10 py-6 lg:py-12 justify-stretch ">
        <div className="section-title">
          <h2 className="font-bold text-3xl mb-10">Main Social Proof</h2>
        </div>
        <div
          className="py-10 px-2 lg:px-5 relative mt-5 section-bg-green"
          style={{
            borderRadius: "10px",
          }}
        >
          <div className="container mx-auto flex flex-col md:flex-row">
            <FirstColumn />
            <SecondColumn />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SocialProof;
