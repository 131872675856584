import React, { useState } from "react";
import downArrow from "../../assets/icons/arrow-down.png";

function SingleFaq({ faqTitle, faqDescription }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className="faq-item "
      style={isActive ? { border: "1px solid #429545" } : {}}
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <i
        className="faq-icon"
        style={
          isActive
            ? {
                border: "1px solid #429545",
                borderRadius: "50%",
                transform: "rotate(-180deg)",
              }
            : {}
        }
      >
        <img
          src={downArrow}
          alt="images/arrow"
          style={
            isActive
              ? { color: "#429545", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={() => setIsActive(!isActive)}
        />
      </i>
      <h2 style={{ color: isActive ? "#429545" : "#191919" }}>{faqTitle}</h2>
      {isActive && (
        <div className="faq-content">
          <p>{faqDescription}</p>
        </div>
      )}
    </div>
  );
}

export default SingleFaq;
