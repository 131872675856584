import React, { useState } from "react";
import eyeslash from "../../assets/icons/eyeslash.png";
import email from "../../assets/icons/envelope.png";
import Divider from "../Divider/Divider";
import SocialAuthentication from "./Social_AUthentication";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signingStart,
  signingFailure,
  signingSuccess,
  clearError,
} from "../../redux/user/userSlice";
import { baseURL } from "../../config";
import { useEffect } from "react";
function LogIn() {
  const [isVisible, setIsVisible] = useState(false);
  const { loaading, error } = useSelector((state) => state.user);
  const [formdata, setFormData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form with data:", formdata);

    try {
      dispatch(signingStart());
      const res = await fetch(`${baseURL}/auth/signin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formdata),
        credentials: "include",
      });
      const data = await res.json();
      if (data.success === false) {
        dispatch(signingFailure(data.message));
        return;
      }

      dispatch(signingSuccess(data));
      const userRole = data.role;
      if (userRole === "writer") {
        navigate("/writer");
      } else if (userRole === "admin") {
        navigate("/admin");
      } else if (userRole === "client") {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
      console.log("Response from server:", data);
    } catch (error) {
      dispatch(signingFailure(error.message));
    }
  };
  const inputclassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1  focus:ring-[#2BC210]";
  return (
    <form className="mt-5 w-full ps-5" onSubmit={handleSubmit}>
      <h2 className="text-2xl text-left  text-gray-900 font-extrabold ">
        Log In
      </h2>
      <div className="mt-4  relative w-full">
        <span className="text-sm text-gray-600">Email address</span>
        <div className="relative w-full">
          <input
            className={inputclassValues}
            type="email"
            id="email"
            placeholder="Enter your email "
            required
            onChange={handleChange}
          />
          <img
            src={email}
            alt="email"
            className="absolute right-3 top-1/2 transform-translate-y-1/2"
            style={{ width: 20, height: 20 }}
          />
        </div>
      </div>
      <div className="mt-4">
        <span className=" text-sm text-gray-600 ">Password</span>
        <div className="relative w-full">
          <input
            className={inputclassValues}
            type={isVisible ? "text" : "password"}
            placeholder="Enter your password"
            required
            id="password"
            onChange={handleChange}
          />
          {isVisible ? (
            <svg
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              width="20"
              height="20"
              onClick={() => setIsVisible(!isVisible)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 6.75a3 3 0 110-6 3 3 0 010 6zM3.75 12a8.25 8.25 0 0115.5 0M12 21.75a9 9 0 01-9-9"
              />
            </svg>
          ) : (
            <img
              src={eyeslash}
              alt="eyeslash"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
              style={{ width: 20, height: 20 }}
              onClick={() => setIsVisible(!isVisible)}
            />
          )}
        </div>
      </div>
      <p className="text-right mt-3 mb-3">
        <Link
          to={"/forgot_password"}
          className="border-b border-green-500 text-sm"
        >
          Forgot password{" "}
        </Link>
      </p>
      <p className="text-right mt-3 mb-3">
        <Link to={"/"} className="border-b border-green-500 text-sm">
          Dont have an account? Sign Up{" "}
        </Link>
      </p>

      <div className="mt-6  mb-3">
        <button
          disabled={loaading}
          type="submit"
          className=" green-button mx-auto w-full flex justify-center items-center text-center"
        >
          {loaading ? "Loading.." : "Log In"}
        </button>
      </div>
      <Divider text="Or" />
      <SocialAuthentication />
      {error && <div className="text-red-500 text-center mt-4">{error}</div>}
    </form>
  );
}

export default LogIn;
