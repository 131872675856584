import React from "react";
import upload from "../../../../assets/svg_icons/upload.svg";
import turnitin from "../../../../assets/svg_icons/turnitin.svg";
import StructureCheckCard from "../../../../components/StructureCheckCard";

function PlagiarismStep1({
  handleNextStep,
  formData,
  handleFileChange,
  fileNames,
  uploadProgress,
}) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  return (
    <form className="w-full lg:px-5">
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
        <div className="flex flex-col">
          {/* Deadline */}
          <label className="text-sm text-gray-800 mt-2 mb-2">Deadline</label>
          <div className="flex space-x-4 mt-2 mb-2">
            {/* 30 mins */}
            <div className="cursor-pointer flex items-center justify-center px-4 py-2  shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] rounded-lg ">
              <input
                type="radio"
                id="30mins"
                name="deadline"
                value={formData.deadline}
                className="font-semibold"
              />
              <label htmlForfor="30mins" className="text-sm ml-3 font-semibold">
                30 mins
              </label>
            </div>
            {/* 1 day */}
            <div className="cursor-pointer flex items-center justify-center px-4 py-2  shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] rounded-lg ">
              <input
                type="radio"
                id="1day"
                name="deadline"
                value={formData.deadline}
                className="font-semibold"
              />
              <label htmlForfor="1day" className="text-sm ml-3 font-semibold">
                1 day
              </label>
            </div>
            {/* 3 days */}
            <div className="cursor-pointer flex items-center justify-center px-4 py-2  shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] rounded-lg ">
              <input
                type="radio"
                id="3days"
                name="deadline"
                value={formData.deadline}
                className="font-semibold"
              />
              <label htmlForfor="3days" className="text-sm ml-3 font-semibold">
                3 days
              </label>
            </div>
          </div>
          {/* Turnitin report & AI check */}
          <div className="shadow-[#D4DFD41F] mt-8 bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transhtmlForm duration-500 transhtmlForm hover:-translate-y-1">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label
                htmlFor="custom-checkbox"
                className="ml-5 font-semibold text-sm"
              >
                Turnitin report & AI check
              </label>
            </div>
            <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
            <div className="text-left text-sm">
              Turnitin report without database storage + AI within 30 minutes.
            </div>
            <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-5 mb-2"></div>
            <div className="flex justify-between items-center">
              <div className="text-success font-semibold text-lg">$90</div>
              <div className="flex-shrink-0">
                <img src={turnitin} alt="turnitin-image" className="h-5" />
              </div>
            </div>
          </div>
          {/* Turnitin report without database storage + AI  */}
          <div className="mt-5">
            <StructureCheckCard
              cardLabel="Turnitin Report + AI Check + Editing of Plagiarized Text + Humanizing AI-Written Text"
              items={["item1", "item2", "item3", "item4"]}
              tagLine={
                <div>
                  <div className="mb-2 text-[14px] font-semibold">
                    USD <span className="text-success text-lg">$0.025</span> per
                    word of the Plagiarized & AI Text
                  </div>
                  <div className="text-[12px] mt-4">
                    You’ll be billed for the Turnitin Report + AI Check. After
                    that, we’ll send an invoice for Plagiarism Editing and
                    Humanizing AI-Written Text.
                  </div>
                </div>
              }
            />
          </div>
          {/* Editing of Plagiarized Text + Humanizing AI-Written Text */}
          <div className="mt-5">
            <StructureCheckCard
              cardLabel="Editing of Plagiarized Text + Humanizing AI-Written Text"
              items={[
                "item1",
                "item2",
                "item3",
                "item4",
                "item5",
                "item6",
                "item7",
                "item8",
                "item9",
              ]}
              tagLine={
                <div>
                  <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-5 mb-2"></div>
                  <div className="flex justify-between items-center">
                    <div className="font-semibold text-md">Price</div>
                    <div className="flex-shrink-0 text-md">
                      <span className="text-success text-lg mr-3"> $O.025</span>
                      per word
                    </div>
                  </div>
                  <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-2 mb-2"></div>
                  <div className="text-md mt-3 mb-2">
                    Select this service if you already have your Turnitin and AI
                    reports.
                  </div>
                  <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-2 mb-2"></div>
                  <label className="mb-2 mt-8">No of words</label>
                  <input
                    className={inputClassValues}
                    type="number"
                    placeholder="number of words"
                    min="0"
                  />
                  <p className="text-sm text-gray-800 text-right mt-1">
                    400 words
                  </p>
                </div>
              }
            />
          </div>
        </div>
        {/* Attach Files here */}
        <div className="mt-6">
          <div className="relative">
            <input
              type="file"
              onChange={handleFileChange}
              multiple
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
            <div className="flex flex-col items-center justify-center border-2 border-[#b2f3a7] border-dotted w-full bg-[#f5f5f5] p-6 rounded-lg">
              <div className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center">
                <img src={upload} alt="upload" className="w-4 h-4" />
              </div>
              <p className="text-center mb-3 mt-3 text-gray-900 text-sm">
                Attach files
              </p>
              {fileNames.length > 0 && (
                <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
                  {fileNames.map((name, index) => (
                    <li key={index}>
                      {name}{" "}
                      {uploadProgress[name] !== undefined &&
                        `(${uploadProgress[name]}%)`}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------End of New form---------------------- */}

      <button
        type="button"
        onClick={handleNextStep}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Submit & Pay
      </button>
    </form>
  );
}

export default PlagiarismStep1;
