import React, { useState } from "react";
import logo from "../assets/logos/hubproof.png";
import { Link } from "react-router-dom";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeepDropdownOpen, setIsDeepDropdownOpen] = useState(false);

  const toggleDeepDropdown = () => {
    setIsDeepDropdownOpen(!isDeepDropdownOpen);
  };
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-[#f2fbf3]  z-10 relative">
      <div className="flex justify-between sm:flex-wrap p-2  mx-auto lg:container bg-white relative z-20 md:rounded-b-3xl">
        <div className="logo">
          <Link to={"/"}>
            <img src={logo} alt="Logo" className="img-fluid w-full max-h-20" />
          </Link>
        </div>
        <div className="flex-grow flex justify-end items-center space-x-4">
          <button className="md:hidden" onClick={toggleDrawer}>
            <svg
              className="w-6 h-6 text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
          <nav
            id="navbar"
            className={`flex-col md:flex-row p-0 md:flex ${
              isOpen ? "flex" : "hidden"
            } md:flex`}
          >
            <ul className="m-0 p-0 flex flex-col md:flex-row list-none items-center">
              <li className="relative">
                <Link
                  to={"/"}
                  className="flex items-center justify-between py-2.5 px-4 text-[14px] text-[#545454] whitespace-nowrap transition-all duration-300 hover:text-[rgb(43,194,43)]"
                >
                  Home
                </Link>
              </li>
              <li className="relative group">
                <Link
                  to={"/"}
                  className="flex items-center justify-between py-2.5 px-4 text-[14px] text-[#545454] whitespace-nowrap transition-all duration-300 group-hover:text-[rgb(43,194,43)]"
                >
                  <span>Services</span>{" "}
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </Link>
                <ul className="absolute left-0 mt-2 p-2.5 bg-white shadow-lg opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-all duration-300">
                  <li className="min-w-[200px]">
                    <Link
                      to={"/selectservices"}
                      className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                    >
                      Select services
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to={"#"}
                      className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)] cursor-pointer"
                      onClick={toggleDeepDropdown}
                    >
                      <span>Deep Drop Down</span>{" "}
                      <i className="bi bi-chevron-right text-[12px]"></i>
                    </Link>
                    <ul
                      className={`absolute left-full mt-2 p-2.5 bg-white shadow-lg transition-all duration-300 ${
                        isDeepDropdownOpen
                          ? "opacity-100 visible w-[300px]"
                          : "opacity-0 invisible"
                      }`}
                    >
                      <li>
                        <Link
                          to={"/"}
                          className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                        >
                          Deep Drop Down 1
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/"}
                          className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                        >
                          Deep Drop Down 2
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/"}
                          className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                        >
                          Deep Drop Down 3
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/"}
                          className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                        >
                          Deep Drop Down 4
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/"}
                          className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                        >
                          Deep Drop Down 5
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={"/"}
                      className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                    >
                      Drop Down 2
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/"}
                      className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                    >
                      Drop Down 3
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/"}
                      className="block py-2.5 px-5 text-[14px] text-[#545454] transition-all duration-300 hover:text-[rgb(43,194,43)]"
                    >
                      Drop Down 4
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to={"/"}
                  className="flex items-center justify-between py-2.5 px-7 text-[15px] text-[#545454] whitespace-nowrap transition-all duration-300 hover:text-[rgb(43,194,43)]"
                >
                  Reviews
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  className="flex items-center justify-between py-2.5 px-7 text-[15px] text-[#545454] whitespace-nowrap transition-all duration-300 hover:text-[rgb(43,194,43)]"
                >
                  Experts
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  className="flex items-center justify-between py-2.5 px-7 text-[15px] text-[#545454] whitespace-nowrap transition-all duration-300 hover:text-[rgb(43,194,43)]"
                >
                  Knowledge base
                </Link>
              </li>
              <li>
                <Link
                  to={"/signup"}
                  className="flex items-center justify-between py-2.5 px-4 text-[14px] text-[#545454] whitespace-nowrap transition-all duration-300 hover:text-[rgb(43,194,43)]"
                >
                  Apply as a free lancer
                </Link>
              </li>
              <li>
                <Link to={"/auth"}>
                  <button className="border-[1px] border-solid border-[rgb(43,194,43)] rounded-[8px] px-[25px] py-[8px] whitespace-nowrap [transition:0.3s] text-[14px] font-semibold flex">
                    Hire top talent
                  </button>
                </Link>{" "}
              </li>

              <li className="me-3">
                <Link
                  to={"/login"}
                  className="flex items-center justify-between py-2.5 px-4 text-[14px] text-[#545454] whitespace-nowrap transition-all duration-300 hover:text-[rgb(43,194,43)]"
                >
                  Log in
                </Link>{" "}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
