import React, { useEffect, useState } from "react";
import { baseURL } from "../../../config";
import DropDownEmail from "../../../components/Header/DropDownEmail";
import DropdownMessage from "../../../components/Header/DropdownMessage";
import { Link } from "react-router-dom";
import { Status } from "../../../components/Cards/Order/Status";
import { useSelector } from "react-redux";

function OrderTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  let userId = currentUser._id;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${baseURL}/service/services/user/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        console.log("Response Status:", response.status);
        const data = await response.json();
        console.log("Response Data:", data);

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }

        const allOrders = Object.entries(data).flatMap(
          ([serviceType, orders]) =>
            orders.map((order) => ({ ...order, serviceType }))
        );

        const sortedOrders = allOrders.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setOrders(sortedOrders);
      } catch (error) {
        console.error("Fetch Error:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [userId]);

  if (loading) {
    return (
      <div className="p-3">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-3">
        <p>Error: {error}</p>
      </div>
    );
  }
  if (orders.length === 0) {
    return (
      <div className="p-3">
        <p>No Orders yet.</p>
      </div>
    );
  }

  return (
    <div className="p-3">
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200 text-black">
          <tr className="bg-[#E9E9E9] px-5">
            <th className="py-2 px-4 text-left">Order ID</th>
            <th className="py-2 px-4 text-left">Subject</th>
            <th className="py-2 px-4 text-left"></th>
            <th className="py-2 px-4 text-left">Sources</th>

            <th className="py-2 px-4 text-left">Pages/Words</th>
            <th className="py-2 px-4 text-left">Budget</th>
            <th className="py-2 px-4 text-left"> Status</th>
            <th className="py-2 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody className="p-6 pt-0">
          {orders.map((order) => (
            <React.Fragment key={order._id}>
              <tr>
                <td colSpan="8" className="bg-[#E9E9E9] p-4 font-semibold"></td>
              </tr>
              <tr>
                <td className="py-2 px-4">
                  {order?.orderDetails?.id} ({order.serviceType})
                </td>
                <td className="py-2 px-4">{order.subject || "N/A"}</td>
                <td className="py-2 px-4">
                  <DropdownMessage /> <DropDownEmail />
                </td>
                <td className="py-2 px-4">{order.noOfSources}</td>
                <td className="py-2 px-4">
                  {order.pageCount
                    ? `${order.pageCount} pages`
                    : order.wordCount
                    ? `${order.wordCount} words`
                    : "N/A"}
                </td>
                <td className="py-2 px-4">${order?.orderDetails?.price}</td>
                <td className=" py-2 px-4 ">
                  <Status status={order?.orderDetails?.status} />
                </td>
                <td className="py-2 px-4">
                  <Link
                    to={`/singleOrder/${order._id}`}
                    className="blue-button flex justify-center"
                  >
                    View Order
                  </Link>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderTable;
