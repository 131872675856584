import React from "react";
import { Link } from "react-router-dom";
import quality from "../../assets/pngs/quality.png";
import submissions from "../../assets/pngs/submissions.png";
import experts from "../../assets/pngs/experts.png";
import pocket from "../../assets/pngs/pocket.png";
import support from "../../assets/pngs/support.png";
import privacy from "../../assets/pngs/privacy.png";
import Reasons from "./Reasons";

function WhySuptal() {
  return (
    <section
      id="why-suptal"
      className="why-suptal bg-[#f2fbf3] border border-[#e4efe4] shadow-md transition-shadow duration-300 ease-in-out"
    >
      <div className="container mx-auto px-3 lg:px-20 py-12 justify-center">
        <div className="section-title text-center mb-12">
          <h2 className="font-bold text-3xl mb-10">
            Why Hubproof is Top choice For Professional & Writing Assistance
          </h2>
          <h6 className="text-xl text-gray-700 mt-2">
            We provide solutions when you need them
          </h6>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <Reasons
            reasonImage={quality}
            reasonTitle="High Quality professional work"
            reasonDescription="One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
          />
          <Reasons
            reasonImage={submissions}
            reasonTitle="Timely submissions"
            reasonDescription="One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
          />
          <Reasons
            reasonImage={experts}
            reasonTitle="Exceptional & reliable experts"
            reasonDescription="One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
          />
          <Reasons
            reasonImage={pocket}
            reasonTitle="Affordable & Pocket friendly services"
            reasonDescription="One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
          />
          <Reasons
            reasonImage={support}
            reasonTitle="24/7 support available"
            reasonDescription="One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
          />
          <Reasons
            reasonImage={privacy}
            reasonTitle="We protect your privacy"
            reasonDescription="One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
          />
        </div>
        <div className="flex items-center justify-center mt-12">
          <Link
            to="/auth"
            className="bg-green-0 shadow-lg text-white font-medium text-lg px-6 py-3 rounded-lg transition duration-500 transform hover:scale-105"
          >
            Hire top talent
          </Link>
        </div>
      </div>
    </section>
  );
}

export default WhySuptal;
