import React from "react";
import DashboardCard from "../../../components/Dashboard/DashboardCard";
import SingleBid from "./SingleBid";

function OffersList({ writerBids }) {
  return (
    <div className="mx-auto  mt-8 p-5 ">
      {writerBids.map((writer) => (
        <DashboardCard
          cardTitle={
            <div className="flex flex-row items-start justify-evenly gap-3 ">
              <div>
                Order completion rate:
                <span className="font-semibold">
                  {writer.orderCompletionRate}
                </span>
              </div>
              <div>
                Delivered on time:{" "}
                <span className="font-semibold">
                  {writer.projectsDeliveredInTime}
                </span>
              </div>
              {writer.hasUnlimitedRevisions && <div>Unlimited revisions</div>}
            </div>
          }
          cardContent={<SingleBid writer={writer} />}
        />
      ))}
    </div>
  );
}

export default OffersList;
