import React from "react";

function Messages() {
  return (
    <div className="mt-4 bg-[#FFFFFF] rounded-[10px] border border-[#E4EFE4] p-5 ">
      Enter all messages here
    </div>
  );
}

export default Messages;
