import React from "react";
import ExpertProfile from "./ExpertProfile";
import ExpertQualifications from "./ExpertQualifications";
import Expertise from "./Expertise";
import likeIcon from "../../assets/svg_icons/like.svg";
import star from "../../assets/svg_icons/rate.svg";

function Expert({
  coverImage,
  expertPhoto,
  expertName,
  expertLevel,
  expertQualifications,
  expertise,
  rating,
  votes,
}) {
  return (
    <div className="expert-item d-flex align-items-start">
      <div className="pic">
        <img src={coverImage} className="img-fluid" alt="immge" />
        <div className="favorite-icon">
          <img src={likeIcon} alt="favorite" className=" w-4 h-4" />
        </div>
        <div className="ratings-icon ">
          <div className="flex items-center space-x-2 mr-2">
            <img src={star} alt="rating" className="w-4 h-4" />
            <p className="font-bold">{rating}</p>
            <p> {votes}</p>
          </div>
        </div>
      </div>
      <div className="expert-info p-0">
        <ExpertProfile
          expertPhoto={expertPhoto}
          expertName={expertName}
          expertLevel={expertLevel}
        />
        <div className="grid justify-start  grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-1 mt-5 expert-qualifications">
          {expertQualifications.map((qualification, index) => (
            <ExpertQualifications
              key={index}
              qualificationName={qualification}
            />
          ))}
        </div>

        <h4 className="mb-2 mt-4">Expert In</h4>

        <ul
          className="grid grid-cols-3 md:grid-cols-2 "
          style={{
            borderBottom: "1px solid #9b9a9a",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {expertise.map((item, index) => (
            <Expertise key={index} className="expertise " expertise={item} />
          ))}
        </ul>
        <div className="grid mx-auto  grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-1 mt-5 ">
          <button className="green-outline-button"> Send Message</button>
          <button className="green-button">Get a Quote</button>
        </div>
      </div>
    </div>
  );
}

export default Expert;
