import React from "react";
import mastercard from "../../assets/payment-icons/mastercard.png";
import alipay from "../../assets/payment-icons/alipay.png";
import applepay from "../../assets/payment-icons/applepay.png";
import visa from "../../assets/payment-icons/visa.png";
import wechat from "../../assets/payment-icons/wechat.png";
import paypal from "../../assets/payment-icons/paypal.png";
import googlepay from "../../assets/payment-icons/gpay.png";

function PaymentSection() {
  return (
    <div className=" mx-20 flex justify-center items-center ">
      <div className="grid grid-cols-2 md:grid-cols-7 sm:mb-2 sm:mr-3 px-20 py-7 gap-1 justify-evenly payment-section">
        <a className="mr-2" href="/">
          <img src={paypal} alt="paypal" />
        </a>

        <a className="mr-2" href="/">
          <img src={mastercard} alt="mastercard" />
        </a>

        <a className="mr-2" href="/">
          <img src={visa} alt="visa" />
        </a>

        <a className="mr-2" href="/">
          <img src={alipay} alt="alipay" />
        </a>

        <a className="mr-2" href="/">
          <img src={wechat} alt="wechat" />
        </a>

        <a className="mr-2" href="/">
          <img src={applepay} alt="applepay" />
        </a>

        <a href="/">
          <img src={googlepay} alt="googlepay" />
        </a>
      </div>
    </div>
  );
}

export default PaymentSection;
