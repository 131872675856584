import React from "react";
import checkIcon from "../../assets/icons/check.png";

function Expertise({ expertise }) {
  return (
    <li className="flex items-center mb-2" style={{ fontsize: "13px" }}>
      <img src={checkIcon} className=" mr-2" alt="icon" />{" "}
      <span>{expertise}</span>
    </li>
  );
}

export default Expertise;
