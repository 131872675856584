import React from "react";

function ArrowButton({ onClick, src, alt, className, displayCircle }) {
  return (
    <div className="arrow-container">
      {displayCircle && <div className="arrow-circle"></div>}
      <button onClick={onClick} className={`arrow-button ${className}`}>
        <img src={src} alt={alt} />
      </button>
    </div>
  );
}

export default ArrowButton;
