import React from "react";

function DashboardCard({ cardTitle, cardContent }) {
  return (
    <div className="w-full bg-[#FFFFFF] rounded-[10px] mb-5 overflow-hidden border border-[#E4EFE4] shadow-[#D4DFD41F] items-start ">
      <div className="bg-[#E9E9E9] p-4">{cardTitle}</div>
      <div className="p-4">{cardContent}</div>
    </div>
  );
}

export default DashboardCard;
