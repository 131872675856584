import React from "react";

function Files() {
  return (
    <div className="mt-4 bg-[#FFFFFF] rounded-[10px] border border-[#E4EFE4] p-5 ">
      Enter list of files here
    </div>
  );
}

export default Files;
