// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "hubproof-bc6ee.firebaseapp.com",
  projectId: "hubproof-bc6ee",
  storageBucket: "hubproof-bc6ee.appspot.com",
  messagingSenderId: "925246534269",
  appId: "1:925246534269:web:1d1b3b3c8afd2f3f40b714",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
