import React from "react";

const Divider = ({ text }) => {
  return (
    <div className="flex items-center my-4">
      <hr className="flex-1 border-t border-[#D7E8D8]" />
      {text && <span className="mx-4 text-gray-500">{text}</span>}
      <hr className="flex-1 border-t border-[#D7E8D8]" />
    </div>
  );
};

export default Divider;
