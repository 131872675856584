import React from "react";
import "./css/thefaqs.css";
import SingleFaq from "./SingleFaq";

function TheFaqs() {
  const faqDescription =
    "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magnafringilla urna porttitor rhoncus dolor purus non.";
  return (
    <section className="section-bg faq">
      <div className="container faq-container mx-auto px-3 lg:px-10 py-12 justify-center ">
        <div className="section-title">
          <h2 className="font-bold text-3xl mb-10">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="flex mx-auto justify-center">
          <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
            <div className="px-1  py-6">
              <SingleFaq
                faqTitle="How do i sign in to Hubproof app?"
                faqDescription={faqDescription}
              />
              <SingleFaq
                faqTitle="How are money transactions handled in the app?"
                faqDescription={faqDescription}
              />
              <SingleFaq
                faqTitle="How do i become an expert?"
                faqDescription={faqDescription}
              />
              <SingleFaq
                faqTitle="How do you make sure experts are verified?"
                faqDescription={faqDescription}
              />
            </div>
            <div className="px-1  py-6">
              <SingleFaq
                faqTitle="Is there a mobile version of the app?"
                faqDescription={faqDescription}
              />
              <SingleFaq
                faqTitle="How long does it take to complete a project?"
                faqDescription={faqDescription}
              />
              <SingleFaq
                faqTitle="How are conflicts handled in the app?"
                faqDescription={faqDescription}
              />
              <SingleFaq
                faqTitle="What services does this app offer?"
                faqDescription={faqDescription}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TheFaqs;
