import React from "react";
import googleIcon from "../../assets/icons/googlesignup.png";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../firebase";
import { useDispatch } from "react-redux";
import { signingSuccess } from "../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../config";
function Oauth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoogleClick = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithPopup(auth, provider);

      const res = await fetch(`${baseURL}/auth/google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: result.user.email,
          name: result.user.displayName,
          photoUrl: result.user.photoURL,
        }),
      });
      if (!res.ok) {
        const errorText = await res.text();
        console.error(`Error: ${res.status} - ${errorText}`);
        throw new Error("Failed to fetch data from the server.");
      }
      const data = await res.json();
      dispatch(signingSuccess(data));
      navigate("/dashboard");
    } catch (error) {
      console.log("Could not signin with Google", error);
      console.log(process.env.REACT_APP_FIREBASE_API_KEY);
    }
  };

  return (
    <button
      className="icon_button mr-3"
      type="button"
      onClick={handleGoogleClick}
    >
      <img src={googleIcon} alt="google-signin" className=" w-4 h-4" />
    </button>
  );
}

export default Oauth;
