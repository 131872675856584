import React from "react";
import arrowcircleright from "../../assets/svg_icons/arrow-circle-right.svg";
import { Link } from "react-router-dom";

function ServiceType({ title, image, paragraph, route }) {
  return (
    <div className=" bg-white border border-[#E4EFE4] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] rounded-[24px] px-7 py-2 cursor-default transition-transform duration-500 transform hover:-translate-x-1">
      <div className="flex flex-row">
        <div className="bg-[#F3FBF4] border-0 rounded-2xl flex items-center justify-center p-2 ">
          <img
            src={image}
            alt={title}
            className=" w-12 h-12 rounded-2xl md:mx-10 mx-7 "
          />
        </div>

        <div className="flex-col  text-left p-3 ml-5">
          <p className="text-black text-lg font-bold mb-2">{title}</p>
          <p className="text-sm text-wrap justify-start items-start">
            {paragraph}
          </p>
        </div>
        <Link to={route}>
          <img
            src={arrowcircleright}
            alt="arrow"
            className=" cursor-pointer ml-auto mt-5 w-20 transform hover:scale-110 transition duration-200 "
          />
        </Link>
      </div>
    </div>
  );
}

export default ServiceType;
