import React from "react";
import OrderTable from "../../../components/Tables/Order/Ordertable";

function ClientBidding() {
  return (
    <div>
      <OrderTable />
    </div>
  );
}

export default ClientBidding;
