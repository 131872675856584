import React, { useState } from "react";
import { Link } from "react-router-dom";
import HomeworkStepper from "../HomeworkHelp/HomeworkStepper";
import backArrow from "../../../../assets/svg_icons/arrow_left_1.svg";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";

import { devUrl } from "../../../../config";

function EditingAndProofReadingService() {
  const steps = [
    {
      stepNumber: "1",
      stepTitle: "Project Brief",
    },
    {
      stepNumber: "2",
      stepTitle: "Popular Add-ons",
    },
  ];
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [activeTab, setActiveTab] = useState("pages");

  const [formData, setFormData] = useState({
    subject: "",
    topic: "",
    typeOfpaper: "",
    files: [],
    details: "",
    pageCount: 0,
    powerPointSlides: 0,
    noOfCharts: 0,
    format: "MLA",
    noOfSources: 0,
    orderDeatils: {
      price: 0,
    },
    deadline: "",
    wordCount: 0,
    structureCheck: false,
    clarityCheck: false,
    documentFormatting: false,
    citationCheck: false,
    plagiarismCheck: false,
    plagiarismEditing: false,
  });
  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const handleSlidesInputIncrease = () => {
    handleInputChange({
      target: {
        id: "powerPointSlides",
        value: formData.powerPointSlides + 1,
      },
    });
  };
  const handleSlidesInputDecrease = () => {
    handleInputChange({
      target: {
        id: "powerPointSlides",
        value: Math.max(0, formData.powerPointSlides - 1),
      },
    });
  };
  const handleSourceInputIncrease = () => {
    handleInputChange({
      target: {
        id: "noOfSources",
        value: formData.noOfSources + 1,
      },
    });
  };
  const handleSourceInputDecrease = () => {
    handleInputChange({
      target: {
        id: "noOfSources",
        value: Math.max(0, formData.noOfSources - 1),
      },
    });
  };
  const handleDecrease = () => {
    if (activeTab === "pages") {
      handleInputChange({
        target: {
          id: "pageCount",
          value: Math.max(0, formData.pageCount - 1),
        },
      });
    } else if (activeTab === "words") {
      handleInputChange({
        target: {
          id: "wordCount",
          value: Math.max(0, formData.wordCount - 50),
        },
      });
    }
  };
  const handleIncrease = () => {
    if (activeTab === "pages") {
      handleInputChange({
        target: {
          id: "pageCount",
          value: formData.pageCount + 1,
        },
      });
    } else if (activeTab === "words") {
      handleInputChange({
        target: {
          id: "wordCount",
          value: formData.wordCount + 50,
        },
      });
    }
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? !!checked : value,
    }));
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const fileUrls = [];
    const names = selectedFiles.map((file) => file.name);
    console.log("formData before files,", formData);

    setFileNames((prevNames) => [...prevNames, ...names]);

    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => formData.append("files", file));

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${devUrl}/uploads/upload-files`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          selectedFiles.forEach((file) => {
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          });
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.fileUrls) {
            fileUrls.push(...response.fileUrls);

            // Update formData with the file URLs
            setFormData((prevFormData) => ({
              ...prevFormData,
              files: [...prevFormData.files, ...fileUrls],
            }));

            console.log("Files uploaded successfully:", fileUrls);
          } else {
            console.error("fileUrls not found in response:", response);
          }
        } else {
          console.error("Error uploading files:", xhr.responseText);
        }
      };

      xhr.onerror = () => {
        console.error("Error uploading files");
      };

      xhr.send(formData);
      console.log("Formdata after files ,", formData);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleNextStep = () => {
    setActiveStep((prevStep) => {
      const currentIndex = steps.findIndex(
        (step) => step.stepNumber === prevStep.stepNumber
      );
      const nextIndex = currentIndex + 1;

      // Check if nextIndex is within the steps range
      if (nextIndex < steps.length) {
        return steps[nextIndex];
      }

      // Return current step if already at the last step
      return prevStep;
    });
  };
  return (
    <div className="container mx-auto bg-[#f5f5f5] p-8">
      <div className="flex flex-row justify-start items-center mb-8">
        <img src={backArrow} alt="Back" className="w-6 mr-3" />
        <Link to="/selectservices" className="text-green-600">
          Back
        </Link>
      </div>

      <div className="flex justify-center mb-8">
        {steps.map((step) => (
          <div
            key={step.stepNumber}
            className={`w-20 h-2 bg-[#439F46] rounded-full mr-2 cursor-pointer ${
              activeStep.stepNumber !== step.stepNumber ? "opacity-20" : ""
            }`}
            onClick={() => setActiveStep(step)}
          />
        ))}
      </div>

      <div className="text-center mb-8">
        <h1>Choose A Proofreading & Editing Package</h1>
      </div>

      <div className="flex w-full min-h-screen">
        <div className="hidden lg:block lg:w-1/4 bg-[#F7FBF7] p-8 rounded-[40px] shadow-md h-90 mt-12">
          <HomeworkStepper
            activeStep={activeStep}
            steps={steps}
            setActiveStep={setActiveStep}
          />
        </div>

        <div className="lg:w-3/4 w-full p-8">
          {activeStep.stepNumber === "1" && (
            <Step1
              formData={formData}
              handleInputChange={handleInputChange}
              handleFileChange={handleFileChange}
              handleNextStep={handleNextStep}
              fileNames={fileNames}
              uploadProgress={uploadProgress}
              handleDecrease={handleDecrease}
              handleIncrease={handleIncrease}
              handleSlidesInputIncrease={handleSlidesInputIncrease}
              handleSlidesInputDecrease={handleSlidesInputDecrease}
              handleSourceInputIncrease={handleSourceInputIncrease}
              handleSourceInputDecrease={handleSourceInputDecrease}
              handleTabClick={handleTabClick}
              activeTab={activeTab}
            />
          )}
          {activeStep.stepNumber === "2" && (
            <Step2
              formData={formData}
              handleInputChange={handleInputChange}
              handleFileChange={handleFileChange}
              handleNextStep={handleNextStep}
              fileNames={fileNames}
              uploadProgress={uploadProgress}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditingAndProofReadingService;
