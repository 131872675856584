import React from "react";

function ExpertQualifications({ qualificationName }) {
  return (
    <div className="expert-qualification">
      <p style={{ fontSize: "13px" }}> {qualificationName}</p>
    </div>
  );
}

export default ExpertQualifications;
