import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logos/logo.svg";
import DropdownUser from "./DropdownUser";
import DropdownMessage from "./DropdownMessage";
import DropDownEmail from "./DropDownEmail";
import Balance from "./Balance";
import Ratings from "./Ratings";
import CurrentLevel from "./CurrentLevel";
import { useSelector } from "react-redux";

function Header({ sidebarOpen, setSidebarOpen }) {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <header className="sticky top-0 z-50 flex w-full bg-white shadow-md">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-md md:px-6 2xl:px-11">
        {/* Sidebar Toggle Button */}
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              setSidebarOpen(!sidebarOpen);
            }}
            className="block rounded-md border border-stroke bg-black p-1.5 shadow-sm lg:hidden"
          >
            <span className="relative block h-5.5 w-5.5 cursor-pointer">
              <span className="absolute right-0 h-full w-full">
                {/* Hamburger Icon */}
                <span
                  className={`block absolute left-0 top-0 my-1 h-0.5 w-0 bg-white rounded-sm transition-all duration-200 ${
                    !sidebarOpen && "!w-full delay-300"
                  }`}
                ></span>
                <span
                  className={`block absolute left-0 top-0 my-1 h-0.5 w-0 bg-white rounded-sm transition-all duration-200 delay-150 ${
                    !sidebarOpen && "delay-400 !w-full"
                  }`}
                ></span>
                <span
                  className={`block absolute left-0 top-0 my-1 h-0.5 w-0 bg-white rounded-sm transition-all duration-200 delay-200 ${
                    !sidebarOpen && "!w-full delay-500"
                  }`}
                ></span>
              </span>
              {/* Close Icon */}
              <span className="absolute right-0 h-full w-full rotate-45">
                <span
                  className={`absolute left-2.5 top-0 block h-full w-0.5 bg-black rounded-sm transition-all duration-200 ${
                    !sidebarOpen && "!h-0 delay-[0]"
                  }`}
                ></span>
                <span
                  className={`absolute left-0 top-2.5 block h-0.5 w-full bg-black rounded-sm transition-all duration-200 delay-400 ${
                    !sidebarOpen && "!h-0 delay-200"
                  }`}
                ></span>
              </span>
            </span>
          </button>
          {/* Logo */}
          <Link className="block flex-shrink-0 lg:hidden" to="/dashboard">
            <img className="w-15 h-15 md:w-10 md:h-10" src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="flex-grow hidden lg:block"></div>

        {/* Right Section */}
        <div className="flex items-center gap-3 2xsm:gap-7">
          {currentUser.role === "writer" && (
            <>
              <CurrentLevel />
              <Ratings />
            </>
          )}

          <Balance />
          <DropDownEmail />
          <DropdownMessage />
          <ul className="flex items-center gap-2 2xsm:gap-4">
            <DropdownUser />
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
