import React from "react";
import upload from "../../../../../assets/svg_icons/upload.svg";

function Step1({
  handleNextStep,
  formData,
  handleFileChange,
  handleInputChange,
  fileNames,
  uploadProgress,
}) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  return (
    <form className="w-full 2xl:px-5">
      <div className="mt-4">
        {/* Notes*/}
        <label className="text-sm text-gray-800">Notes</label>
        <textarea
          id="projectBrief"
          rows="4"
          placeholder="Mention any notes you have "
          className={inputClassValues}
          value={formData.projectBrief}
          onChange={handleInputChange}
        />
      </div>

      {/* Attach files */}
      <div className="mt-8">
        <div className="relative">
          <input
            type="file"
            onChange={handleFileChange}
            multiple
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <div className="flex flex-col items-center justify-center border-2 border-[#b2f3a7] border-dotted w-full bg-[#f5f5f5] p-6 rounded-lg">
            <div className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center">
              <img src={upload} alt="upload" className="w-4 h-4" />
            </div>
            <p className="text-center mb-3 mt-3 text-gray-900 text-sm">
              Attach files
            </p>
            {fileNames.length > 0 && (
              <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
                {fileNames.map((name, index) => (
                  <li key={index}>
                    {name}{" "}
                    {uploadProgress[name] !== undefined &&
                      `(${uploadProgress[name]}%)`}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* Next Button */}
      <button
        type="button"
        onClick={handleNextStep}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Next
      </button>
    </form>
  );
}

export default Step1;
