import React from "react";

function DropDownEmail() {
  return (
    <button
      type="button"
      className="relative inline-flex items-center p-2 md:p-3 text-sm font-medium text-center text-white border border-[#F4F4F4] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-4 w-4 md:h-6 md:w-6 fill-[#439F46]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
        />
      </svg>
      <span className="sr-only">Notifications</span>
      <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-[#439F46] bg-green border border-[#439F46] rounded-full -top-1 -right-1 md:w-6 md:h-6">
        20
      </div>
    </button>
  );
}

export default DropDownEmail;
