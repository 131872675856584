import React from "react";
import { useNavigate } from "react-router-dom";
import signuporlogin from "../../assets/background/login.png";
import hubProofLogo from "../../assets/logos/Hubproof.svg";
import LogIn from "../../components/Auth/LogIn";

function LogInPage() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <div className="grid grid-cols-1 xl:grid-cols-12 gap-1">
      <div className="hidden xl:block xl:col-span-7 ">
        <img
          src={signuporlogin}
          alt="signuporlogin"
          className="h-screen w-auto object-fit"
        />
      </div>
      <div className="md:col-span-5 flex flex-col justify-center items-center p-7 md:ps-14">
        <img
          src={hubProofLogo}
          alt="logo"
          onClick={handleLogoClick}
          className="img-fluid max-h-40 mt-5 cursor-pointer"
        />
        <LogIn />
      </div>
    </div>
  );
}

export default LogInPage;
