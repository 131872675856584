import React from "react";
import tick from "../assets/svg_icons/green-tick.svg";

function StructureCheckCard({
  cardLabel,
  items,
  tagLine,
  checkBoxVisible,
  subTitle,
  flexTitle,
  field,
  handleInputChange,
  formData,
}) {
  return (
    <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] cursor-default transition-transhtmlForm duration-500 transhtmlForm hover:-translate-y-1 flex flex-col h-full">
      {flexTitle && (
        <div className="flex flex-row justify-between">
          <div className="text-md font-semibold">{flexTitle.init ?? ""}</div>
          <div className="text-sm">{flexTitle.amount ?? ""}</div>
        </div>
      )}
      {cardLabel && (
        <div className="flex items-center">
          <input
            id={field}
            onChange={handleInputChange}
            checked={formData[field]}
            type="checkbox"
            className={`${
              checkBoxVisible === false
                ? "hidden"
                : "appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md checked:bg-[#2BC210] focus:outline-[#E4EFE4] focus:ring-[#2BC210]"
            }`}
          />
          <label
            htmlFor="custom-checkbox"
            className="ml-5 font-semibold text-sm"
          >
            {cardLabel}
          </label>
        </div>
      )}

      <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
      <div className="ms-0 mt-2 mb-2 text-sm text-left text-graydark font-bold">
        <p> {subTitle}</p>
      </div>
      <div className="flex-1 overflow-auto">
        <div className="grid grid-cols-1">
          {items.map((item, index) => (
            <div className="flex items-center mb-3" key={index}>
              <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
              <span className="text-sm">{item}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-2 mb-3"></div>
      <div className="ms-0 text-[12px] text-left text-graydark font-bold">
        {tagLine}
      </div>
    </div>
  );
}

export default StructureCheckCard;
