import React from "react";
import facebook from "../../assets/social-icons/facebook.png";
import instagram from "../../assets/social-icons/instagram.png";
import twitter from "../../assets/social-icons/twitter.png";
import youtube from "../../assets/social-icons/youtube.png";

function CopyRight() {
  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-center mt-5">
        <div className="copyright">
          &copy; 2024 &nbsp;
          <span>hubproof.com</span> &nbsp; All Rights Reserved.
        </div>
        <div className="social-links mt-3">
          <a href="/" className="twitter">
            <img src={twitter} alt="twitter" />
          </a>
          <a href="/" className="facebook">
            <img src={facebook} alt="facebook" />
          </a>
          <a href="/" className="instagram">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="/" className="instagram">
            <img src={youtube} alt="youtube" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CopyRight;
