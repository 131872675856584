import React from "react";

function DropdownMessage() {
  return (
    <button
      type="button"
      className="relative inline-flex items-center p-2 md:p-3 text-sm font-medium text-center text-white border border-[#F4F4F4] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className="h-4 w-4 md:h-5 md:w-5 fill-[#439F46]"
        viewBox="0 0 50 50"
      >
        <path d="M 25 0 C 22.800781 0 21 1.800781 21 4 C 21 4.515625 21.101563 5.015625 21.28125 5.46875 C 15.65625 6.929688 12 11.816406 12 18 C 12 25.832031 10.078125 29.398438 8.25 31.40625 C 7.335938 32.410156 6.433594 33.019531 5.65625 33.59375 C 5.265625 33.878906 4.910156 34.164063 4.59375 34.53125 C 4.277344 34.898438 4 35.421875 4 36 C 4 37.375 4.84375 38.542969 6.03125 39.3125 C 7.21875 40.082031 8.777344 40.578125 10.65625 40.96875 C 13.09375 41.472656 16.101563 41.738281 19.40625 41.875 C 19.15625 42.539063 19 43.253906 19 44 C 19 47.300781 21.699219 50 25 50 C 28.300781 50 31 47.300781 31 44 C 31 43.25 30.847656 42.535156 30.59375 41.875 C 33.898438 41.738281 36.90625 41.472656 39.34375 40.96875 C 41.222656 40.578125 42.78125 40.082031 43.96875 39.3125 C 45.15625 38.542969 46 37.375 46 36 C 46 35.421875 45.722656 34.898438 45.40625 34.53125 C 45.089844 34.164063 44.734375 33.878906 44.34375 33.59375 C 43.566406 33.019531 42.664063 32.410156 41.75 31.40625 C 39.921875 29.398438 38 25.832031 38 18 C 38 11.820313 34.335938 6.9375 28.71875 5.46875 C 28.898438 5.015625 29 4.515625 29 4 C 29 1.800781 27.199219 0 25 0 Z"></path>
      </svg>
      <span className="sr-only">Chat</span>
      <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-[#439F46] bg-green border border-[#439F46] rounded-full -top-1 -right-1 md:w-6 md:h-6">
        5
      </div>
    </button>
  );
}

export default DropdownMessage;
