import React from 'react';

function TextColumn({stepNumber, stepTitle, stepDescription}) {
  return (
    <>
      <p className="numberText">{stepNumber}</p>
      <p className="numberDescription">{stepTitle}</p>
      <p>{stepDescription}</p>
    </>
  );
}

export default TextColumn;