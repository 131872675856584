import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Bids() {
  const [bidCount, setBidCount] = useState(0);
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (currentUser?.role !== "writer") {
      const fetchBidCount = () => {
        const fetchedBidCount = 5; // Simulate fetching bid count
        setBidCount(fetchedBidCount);
      };

      fetchBidCount();
    }
  }, [currentUser]);

  return (
    <div>
      {currentUser?.role === "writer" ? (
        <button className="green-button flex justify-center cursor-default">
          Place Bid
        </button>
      ) : (
        <Link to={"/singleOrder"} className="green-button flex justify-center">
          View Bids {bidCount}
        </Link>
      )}
    </div>
  );
}

export default Bids;
