export const Status = ({ status }) => {
  let color;
  let text;

  switch (status) {
    case "pending":
      color = "text-red-500 bg-red-100";
      text = "Pending";
      break;
    case "delivered":
      color = "text-green-500 bg-green-100";
      text = "Delivered";
      break;
    case "cancelled":
      color = "text-gray-500 bg-gray-100";
      text = "Cancelled";
      break;
    default:
      color = "text-gray-500 bg-gray-100";
      text = "Unknown";
  }

  return (
    <span
      className={`text-center ${color} border rounded-lg px-2 py-1 inline-block text-sm font-medium`}
    >
      {text}
    </span>
  );
};
