import React from "react";
import appstore from "../../assets/app-icons/applestore.png";
import playstore from "../../assets/app-icons/googleplay.png";

function AppButtons() {
  return (
    <div className="flex justify-center items-center  app-buttons">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1 py-6">
        <a className="p-4" href="/">
          <img src={appstore} alt="appstore" />
        </a>
        <a className="p-4" href="/">
          <img src={playstore} alt="playstore" />
        </a>
      </div>
    </div>
  );
}

export default AppButtons;
