import React, { useState } from "react";
import eyeslash from "../../assets/icons/eyeslash.png";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../config";
export default function SignUpWriter() {
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(null);
  const [loaading, setLoading] = useState(false);
  const [formdata, setFormData] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form with data:", formdata);
    setLoading(true);
    try {
      const res = await fetch(`${baseURL}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formdata, role: "writer" }),
      });

      const data = await res.json();
      if (data.success === false) {
        setError(data.message);
        setLoading(false);
        return;
      }
      setLoading(false);
      setError(null);
      navigate("/writer");
      console.log("Response from server:", data);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };
  const inputclassValues =
    "w-full  bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-5 w-full ">
        <h2 className="text-2xl text-left font-extrabold text-gray-900 mb-4">
          Sign up
        </h2>
        <div className="relative w-full">
          <span className="text-sm text-gray-800">Full Name</span>
          <input
            className={`${inputclassValues} w-full pr-20`}
            id="fullname"
            type="text"
            placeholder="Enter your Name"
            required
            onChange={handleChange}
          />
        </div>
        <div className="mt-4">
          <span className="text-sm text-gray-800">Username</span>
          <input
            className={inputclassValues}
            type="text"
            id="username"
            placeholder="Enter your Username"
            required
            onChange={handleChange}
          />
        </div>
        <div className="mt-4">
          <span className="text-sm text-gray-800">Email</span>
          <input
            className={inputclassValues}
            type="email"
            id="email"
            placeholder="Enter your email address"
            required
            onChange={handleChange}
          />
        </div>
        <div className="mt-4">
          <span className="text-sm text-gray-800">Password</span>
          <div className="relative w-full">
            <input
              className={inputclassValues}
              type={isVisible ? "text" : "password"}
              placeholder="Enter your password"
              required
              id="password"
              onChange={handleChange}
            />
            {isVisible ? (
              <svg
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                width="20"
                height="20"
                onClick={() => setIsVisible(!isVisible)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 6.75a3 3 0 110-6 3 3 0 010 6zM3.75 12a8.25 8.25 0 0115.5 0M12 21.75a9 9 0 01-9-9"
                />
              </svg>
            ) : (
              <img
                src={eyeslash}
                alt="eyeslash"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                style={{ width: 20, height: 20 }}
                onClick={() => setIsVisible(!isVisible)}
              />
            )}
          </div>
        </div>
        <div className="mt-8">
          <button
            disabled={loaading}
            onClick={handleSubmit}
            type="submit"
            className=" green-button mx-auto w-full flex justify-center items-center text-center "
          >
            {loaading ? "Loading.." : "Sign Up"}
          </button>
        </div>
      </form>
      {error && <div className="text-red-500 text-center mt-4">{error}</div>}
    </>
  );
}
