import React, { useState, useEffect } from "react";
import OrderDetails from "./OrderDetails/OrderDetails";
import ResolutionCenter from "./ResolutionCenter";
import OrderUsersDetails from "./OrderUsersDetails";
import PackageDetails from "./ProjectDetails/PackageDetails";
import Messages from "./ProjectDetails/Messages";
import ProjectBrief from "./ProjectDetails/ProjectBrief";
import Files from "./ProjectDetails/Files";
import { useParams } from "react-router-dom";
import { baseURL } from "../../config";

function SingleOrder() {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null); // Initialize as null or an empty object
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(
          `${baseURL}/service/homework_id/${orderId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        console.log("Response Status:", response.status);
        const data = await response.json();
        console.log("Response Data:", data);
        setOrderDetails(data); // Set the data directly
      } catch (error) {
        console.error("Fetch Error:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderDetails();
  }, [orderId]);

  const projectTabs = [
    { stepNumber: 1, stepTitle: "Project brief" },
    { stepNumber: 2, stepTitle: "Package & Details" },
    { stepNumber: 3, stepTitle: "Messages (4)" },
    { stepNumber: 4, stepTitle: "Files (10)" },
    { stepNumber: 5, stepTitle: "Resolution (0)" },
  ];

  const [activeStep, setActiveStep] = useState(projectTabs[0]);

  const orderInformation = orderDetails
    ? [
        { title: "Category", subtitle: "Homework" },
        { title: "Order Id", subtitle: orderDetails.id || "N/A" },
        { title: "Subject", subtitle: orderDetails.subject || "N/A" },
        { title: "Format", subtitle: orderDetails.format || "APA" },
        { title: "Sources", subtitle: orderDetails.noOfSources || "N/A" },
        {
          title: "Pages / Words",
          subtitle: orderDetails.pageCount || orderDetails.wordCount,
        },
        { title: "Slides", subtitle: orderDetails.powerPointSlides || "N/A" },
      ]
    : [];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderDetails) {
    return <div>No order details available.</div>;
  }

  return (
    <div className="mx-auto p-5">
      {/* Order details */}
      <OrderDetails orderInformation={orderInformation} />
      {/* Project Details */}
      <div className="mt-4 bg-[#FFFFFF] rounded-[25px] border border-[#E4EFE4] lg:p-5 p-2">
        {/* The details tab */}
        <div className="lg:ms-5 mt-10 mb-5 p-1 bg-[#0A0D0A] rounded-[10px] inline-flex">
          {projectTabs.map((step) => (
            <button
              key={step.stepNumber}
              className={`px-4 py-3 rounded-md text-sm ${
                activeStep.stepNumber === step.stepNumber
                  ? "bg-white text-black font-semibold"
                  : "bg-gray-700 text-white"
              }`}
              onClick={() => setActiveStep(step)}
            >
              {step.stepTitle}
            </button>
          ))}
        </div>
        {/* Conditionally render based on active step */}
        {activeStep.stepNumber === 1 && <ProjectBrief />}
        {activeStep.stepNumber === 2 && <PackageDetails />}
        {activeStep.stepNumber === 3 && <Messages />}
        {activeStep.stepNumber === 4 && <Files />}
        {activeStep.stepNumber === 5 && <ResolutionCenter />}
      </div>
      {/* Client, Writer, and Editor profiles */}
      <OrderUsersDetails />
    </div>
  );
}

export default SingleOrder;
