import React, { useState } from "react";
import arrowRight from "../../../assets/icons/arrowright.svg";

function DeadlineExtensionForm({ closeModal }) {
  // Original date //todo:get actual original delivery date of the order
  const originalDate = new Date();
  // Format the date as desired (e.g., "Month Day, Year")
  const formattedDate = originalDate.toLocaleDateString("en-US", {
    weekday: "short", // Full name of the day
    year: "numeric", // Four-digit year
    month: "short", // Full name of the month
    day: "numeric", // Day of the month
  });
  // State to store the number of days and calculated new date
  const [addedDays, setAddedDays] = useState(0);
  const [newDate, setNewDate] = useState(formattedDate);

  // Function to handle number of days change and update new date
  const handleDaysChange = (e) => {
    const days = parseInt(e.target.value, 10) || 0; // Get number of days from input
    setAddedDays(days);

    // Calculate the new date based on the added days
    const updatedDate = new Date(originalDate);
    updatedDate.setDate(originalDate.getDate() + days); // Add the days to the original date

    // Format and set the new date
    setNewDate(
      updatedDate.toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    );
  };

  const inputClassValues =
    "w-full bg-[#f5f5f5] text-sm text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1  focus:ring-[#2BC210]";
  return (
    <div className="p-1 ">
      <div
        class="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
        role="alert"
      >
        <svg
          class="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span class="sr-only">Info</span>
        <div>
          <span class="font-medium">Sometimes</span> you just need more time,
          however keep in mind this might affect client satisfaction.
        </div>
      </div>
      {/* The form */}
      <form className="mt-5 w-full  ">
        {/* How may days */}
        <div className="w-full mb-8">
          <div className="mt-2">
            <label className="text-sm text-gray-800">
              How many days do you want to add to the original date?
            </label>
            <div className="relative">
              <input
                id="deadlineExtension"
                className={inputClassValues}
                type="number"
                min="1"
                max="60"
                placeholder="Enter number of days"
                value={addedDays}
                onChange={handleDaysChange}
              />
            </div>
            <p className="text-sm text-gray-800 text-left mt-1">
              You can ask for up to 60 days.
            </p>
          </div>
        </div>
        {/* Original date vs New date */}
        <div className="w-full mb-8">
          <div className="flex flex-row justify-between">
            <div className=" flex flex-col">
              <span className="text-md font-semibold mb-3">Original date</span>
              <span className="text-md font-serif mb-3">{formattedDate}</span>
            </div>
            <img src={arrowRight} alt="arrow" className="w-8 h-3 my-auto" />
            <div className=" flex flex-col">
              <span className="text-md font-semibold mb-3">New date</span>
              <span className="text-md font-serif mb-3">{newDate}</span>
            </div>
          </div>
        </div>
        <div className="mt-2 mb-8">
          <label className="text-sm text-gray-800">
            Help the client understand
          </label>
          <div className="relative">
            <textarea
              id="extensionReason"
              minLength={5}
              rows="4"
              placeholder="Enter your reason for requiring an extension"
              className={inputClassValues}
              // value={formData.details}
              // onChange={handleInputChange}
            />
          </div>
          <p className="text-sm text-gray-800 text-left mt-1">
            Enter at least 5 characters
          </p>
        </div>
        {/* Buttons */}
        <div className="flex flex-row justify-end gap-1">
          {/* Cancel */}
          <button
            type="button"
            onClick={closeModal}
            className=" py-3 px-6 text-center text-sm border border-[#f5f5f6] bg-[#f5f5f5] text-black cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
          >
            Cancel
          </button>
          {/* Submit */}
          <button
            type="button"
            className="py-3 px-6  text-center text-sm border border-[#AFD1B0] bg-[#439F46] text-white cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
          >
            Send Request
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeadlineExtensionForm;
