import React, { useState } from "react";
import HorizontalDivider from "../../Divider/HorizontalDivider";
import VerticalDivider from "../../Divider/VerticalDivider";
import DeadlineExtensionForm from "./DeadlineExtensionForm";

function DeadlineStatus() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" rounded-[10px] bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F] p-3">
      {/* Deadline */}
      <div className="font-bold text-md mb-3 mt-3 text-left">Deadline</div>

      {/* Time left to deliver */}
      <div className="font-semibold text-sm mb-3 text-left">
        Time left to deliver
      </div>
      <HorizontalDivider color="#DDEFDE" />
      <div className="flex flex-row justify-evenly lg:gap-3 gap-2 mt-5 ">
        {/* Days */}
        <div className="flex flex-row mt-2  gap-3 lg:ml-3">
          <div className="flex flex-col ">
            <span className="font-serif font-extrabold mb-3"> 01 </span>
            <span className=" mb-1 text-[13px]"> Days</span>
          </div>
          <VerticalDivider height="h-12" color="#E3E6F2" />
        </div>
        {/* Hours */}
        <div className="flex flex-row gap-3  mt-2 lg:mr-3">
          <div className="flex flex-col ">
            <span className="font-serif  font-extrabold mb-3">12</span>
            <div className="mb-1 text-[13px]"> Hours</div>
          </div>
          <VerticalDivider height="h-12" color="#E3E6F2" />
        </div>
        {/* Minutes */}
        <div className="flex flex-row gap-3  mt-2 lg:mr-3">
          <div className="flex flex-col ">
            <span className="font-serif font-extrabold mb-3"> 30 </span>
            <span className=" mb-1 text-[13px]"> Minutes</span>
          </div>
          <VerticalDivider height="h-12" color="#E3E6F2" />
        </div>
        {/* Seconds */}
        <div className="flex flex-row gap-3 mt-2 lg:mr-3">
          <div className="flex flex-col ">
            <span className="font-serif font-extrabold mb-3"> 08 </span>
            <span className="mb-1 text-[13px]"> Seconds</span>
          </div>
        </div>
      </div>
      <HorizontalDivider color="#DDEFDE" />
      <div className="flex flex-col justify-center mx-auto items-center mt-4">
        {/* Deliver now button */}
        <button
          type="button"
          className="mt-5 py-3 px-6 mx-auto flex justify-center items-center text-center text-sm border border-[#AFD1B0] bg-[#439F46] text-white cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
        >
          Deliver Now
        </button>
        {/* Extend delivery button */}
        <button
          onClick={openModal}
          className="p-1 mt-5 mx-auto flex justify-center items-center  text-sm text-center border-b border-[#0E120E] cursor-pointer font-semibold rounded-[3px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
        >
          Extend delivery date
        </button>
        {/* Extend delivery date modal */}
        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-white rounded-[20px] p-5 w-full max-w-lg  relative shadow  border border-[#E4EFE4] h-3/4 overflow-y-auto scrollbar-hide">
              <div className="flex flex-row justify-between mb-4">
                <div className="text-lg text-left font-bold">
                  Request: Extend delivery date
                </div>

                <button
                  onClick={closeModal}
                  className="w-8 h-8 bg-white border border-[#EDF5EE] rounded-full flex items-center justify-center hover:bg-gray-100 transform transition-transform duration-300 hover:scale-105"
                >
                  {/* Cancel icon SVG */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              {/* Enter form here */}
              <DeadlineExtensionForm closeModal={closeModal} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeadlineStatus;
