import React from "react";
import { Link } from "react-router-dom";

function BecomeAnExpertNav() {
  return (
    <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden md:max-w-2xl mt-5">
      <div className="p-4 bg-[#E9E9E9] text-black text-lg font-bold">
        Become an Expert
      </div>
      <div className="p-6 grid grid-cols-3 gap-4">
        <Link
          to={"/homework"}
          className="flex font-semibold  justify-start col-span-2"
        >
          Homework Help{" "}
        </Link>

        <Link
          to={"/proofreading"}
          className="flex font-semibold  justify-start col-span-2"
        >
          Proofreading & Editing{" "}
        </Link>

        <Link
          to={"/citation"}
          className="flex font-semibold  justify-start col-span-2"
        >
          Citation Editing{" "}
        </Link>
        <Link
          to={"/resume"}
          className="flex font-semibold  justify-start col-span-2"
        >
          Resume Editing{" "}
        </Link>
        <Link
          to={"/plagiarism"}
          className="flex font-semibold  justify-start col-span-2"
        >
          Plagiarism & Ai{" "}
        </Link>
      </div>
    </div>
  );
}

export default BecomeAnExpertNav;
