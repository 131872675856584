import React from "react";
import OrderStatus from "./OrderStatus";
import DeadlineStatus from "./DeadlineStatus";

function OrderDetails({ orderInformation }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-5 md:gap-3 gap-4 mt-4 bg-[#FFFFFF] rounded-[25px] border border-[#E4EFE4] p-5 ">
      {/* General Order details */}
      <div className=" rounded-[10px] bg-[#FFFFFF] border border-[#E4EFE4] shadow shadow-[#D4DFD41F]">
        {/* Enter list of order details here */}
        <div className="mx-auto p-6">
          {orderInformation.map((order) => (
            <div className="relative p-3 mt-2 rounded-[20px] border-[#484B48]">
              <div className="absolute inset-0 bg-[#484B48] opacity-[0.05] rounded-[10px] pointer-events-none"></div>
              <div className="relative">
                {/*specific order detail */}
                <div className="flex flex-row gap-2 justify-between ">
                  <span className="text-md">{order.title}</span>
                  <span className="text-md font-semibold">
                    {order.subtitle}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Order status */}
      <OrderStatus />
      {/* Deadline card */}
      <DeadlineStatus />
    </div>
  );
}

export default OrderDetails;
