import React from "react";
import Expert from "./Expert";
import expert1 from "../../assets/pngs/experts/expert1.png";
import expert2 from "../../assets/pngs/experts/expert2.png";
import expert3 from "../../assets/pngs/experts/expert3.png";
import user2 from "../../assets/pngs/experts/temp2.png";
import user3 from "../../assets/pngs/experts/temp3.png";
import "./css/experts.css";

function Experts() {
  //Temporary list of experts
  const expertsList = [
    {
      coverImage: expert1,
      expertPhoto: expert1,
      expertName: "John Doe",
      expertLevel: "level1",
      expertQualifications: ["Resume writing", "Editing & Proofreading"],
      rating: "4.5",
      votes: "(450)",
      expertise: [
        "Resume",
        "Editing ",
        "Proofreading",
        "Writing",
        "LinkedIn",
        "Cover letter",
        "Study",
      ],
    },
    {
      coverImage: expert2,
      expertPhoto: user2,
      expertName: "Jane Doe",
      expertLevel: "level2",
      expertQualifications: ["Resume writing", "Editing & Proofreading"],
      expertise: [
        "Resume",
        "Editing ",
        "Proofreading",
        "Writing",
        "LinkedIn",
        "Cover letter",
        "Study",
      ],
      rating: "5",
      votes: "(20)",
    },
    {
      coverImage: expert3,
      expertPhoto: user3,
      expertName: "Matt Smith",
      expertLevel: "level3",
      expertQualifications: ["Resume writing", "Editing & Proofreading"],
      expertise: [
        "Resume",
        "Editing ",
        "Proofreading",
        "Writing",
        "LinkedIn",
        "Cover letter",
        "Study",
      ],
      rating: "4",
      votes: "(53)",
    },
  ];

  return (
    <section className="  bg-[#f5f5f5] experts  centered-section">
      <div
        className="container mx-auto  section-bg-green py-12 px-3 lg:px-12 justify-center "
        style={{ borderRadius: "20px" }}
      >
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-10 p-4 heading">
            <h2>Who Are The Experts</h2>
            <h6>
              Who Are the Experts We work with well trained and qualified
              experts with diverse knowhow in multiple disciplines. We are one
              stop shop for your professional and academic goals. Excel with us.
            </h6>
          </div>

          <div className="col-span-2">
            <button className="expertsButton"> See all Experts </button>
          </div>
        </div>
        <div className="grid  grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3  gap-2 mt-5">
          {expertsList.map((expert, index) => (
            <Expert
              key={index}
              coverImage={expert.coverImage}
              expertPhoto={expert.expertPhoto}
              expertName={expert.expertName}
              expertLevel={expert.expertLevel}
              expertQualifications={expert.expertQualifications}
              expertise={expert.expertise}
              votes={expert.votes}
              rating={expert.rating}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Experts;
