import React, { useState } from "react";
import writerPhoto1 from "../../assets/pngs/experts/expert1.png";
import writerPhoto2 from "../../assets/pngs/experts/expert2.png";
import writerPhoto3 from "../../assets/pngs/experts/expert3.png";
import backArrow from "../../assets/svg_icons/black-arrow-left.svg";
import SingleOrder from "./SingleOrder";
import OffersList from "../../pages/client/bidding/OffersList";
import { useNavigate } from "react-router-dom";

function SingleOrderInformation() {
  const [selectedTab, setSelectedTab] = useState("orderDetails");
  //Temporary list of bid offers presented by writers
  const navigate = useNavigate();
  const writerBids = [
    {
      orderCompletionRate: 997,
      projectsDeliveredInTime: 40,
      profilePhoto: writerPhoto1,
      expertLevel: "Expert",
      hasUnlimitedRevisions: true,
      writerName: "John Doe",
      rating: "4.5",
      reviews: "300",
      price: "10.00",
      chatsCount: 5,
      isActive: true,
      ordersInProgress: 20,
      bidInformation:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident. It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One mayspeculate that over the course of time certain letters were added ordeleted at various positions within the text.",
    },
    {
      orderCompletionRate: 990,
      projectsDeliveredInTime: 30,
      profilePhoto: writerPhoto2,
      expertLevel: "Beginner",
      hasUnlimitedRevisions: true,
      writerName: "Jane Doe",
      rating: "3.2",
      reviews: "20",
      price: "9.80",
      isActive: false,
      chatsCount: 1,
      ordersInProgress: 5,
      bidInformation:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident. It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One mayspeculate that over the course of time certain letters were added ordeleted at various positions within the text.",
    },
    {
      orderCompletionRate: 50,
      projectsDeliveredInTime: 40,
      profilePhoto: writerPhoto3,
      expertLevel: "level 2",
      hasUnlimitedRevisions: false,
      writerName: "Edward Pulisic",
      rating: "5",
      reviews: "450",
      price: "12.50",
      isActive: true,
      chatsCount: 0,
      ordersInProgress: 10,
      bidInformation:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident. It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One mayspeculate that over the course of time certain letters were added ordeleted at various positions within the text.",
    },
  ];

  return (
    <div>
      {/* Back Button  */}
      <div className="flex flex-row justify-start items-center mb-8">
        <button
          onClick={() => navigate(-1)}
          className=" ms-5 mt-10  p-3 mx-auto flex justify-center items-center text-center border border-[#AFD1B0] bg-[#FFFFFF]  cursor-pointer font-semibold rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] transform transition-transform duration-300 hover:scale-105"
        >
          <img src={backArrow} className="w-6 mr-1" alt="back-arrow" />
          Back
        </button>
      </div>

      {/* Order Details and Bid offers tabs */}
      <div className="ms-5 mt-10 p-1  bg-[#0A0D0A] rounded-[10px] inline-flex">
        <button
          className={`px-4 py-3 mr-8 rounded-md ${
            selectedTab === "orderDetails"
              ? "bg-white text-black font-semibold"
              : "bg-gray-700 text-white"
          }`}
          onClick={() => setSelectedTab("orderDetails")}
        >
          Order details
        </button>
        <button
          className={`px-4 py-3 rounded-md ${
            selectedTab === "numberOfOffers"
              ? "bg-white text-black font-semibold"
              : "bg-gray-700 text-white"
          }`}
          onClick={() => setSelectedTab("numberOfOffers")}
        >
          Offers ({writerBids.length})
        </button>
      </div>
      {/* The tabs' content */}
      {/* 1: Order details */}
      {selectedTab === "orderDetails" && <SingleOrder />}
      {/* 2: List of offers */}
      {selectedTab === "numberOfOffers" && (
        <OffersList writerBids={writerBids} />
      )}
    </div>
  );
}

export default SingleOrderInformation;
