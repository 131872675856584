import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../../../../config";
import { useNavigate } from "react-router-dom";
import subject from "../../../../../assets/svg_icons/subject.svg";
import topic from "../../../../../assets/svg_icons/newMessage.svg";
import upload from "../../../../../assets/svg_icons/upload.svg";
import dollar from "../../../../../assets/svg_icons/dollar.svg";

function HomeworkStep3({
  formData,
  handleInputChange,
  setFormData,
  activeTab,
  handleIncrease,
  handleDecrease,
  handleSlidesInputIncrease,
  handleSlidesInputDecrease,
  handleSourceInputIncrease,
  handleSourceInputDecrease,
  handleTabClick,
}) {
  const [newFileNames, setNewFileNames] = useState([]);
  const [newUploadProgress, setNewUploadProgress] = useState({});
  const [uploadComplete, setUploadComplete] = useState(true);

  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {}, [newUploadProgress, newFileNames]);
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";
  const handleNewFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const names = selectedFiles.map((file) => file.name);

    setNewFileNames((prevNames) => [...prevNames, ...names]);
    setUploadComplete(false);

    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => formData.append("files", file));

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${baseURL}/uploads/upload-files`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          selectedFiles.forEach((file) => {
            setNewUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          });
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.fileUrls) {
            // Add new file URLs to formData
            setFormData((prevFormData) => ({
              ...prevFormData,
              files: [...prevFormData.files, ...response.fileUrls],
            }));

            // Check if all files are uploaded
            setUploadComplete(true);
            console.log("New files uploaded successfully:", response.fileUrls);
          } else {
            console.error("fileUrls not found in response:", response);
            setUploadComplete(true); // Ensure upload status is updated
          }
        } else {
          console.error("Error uploading new files:", xhr.responseText);
          setUploadComplete(true); // Ensure upload status is updated
        }
      };

      xhr.onerror = () => {
        console.error("Error uploading new files");
        setUploadComplete(true); // Ensure upload status is updated
      };

      xhr.send(formData);
    } catch (error) {
      console.error("Error uploading new files:", error);
      setUploadComplete(true); // Ensure upload status is updated
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting homework brief:", formData);

    try {
      const res = await fetch(`${baseURL}/service/homework/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ ...formData, userRef: currentUser._id }),
      });

      console.log(currentUser);

      const data = await res.json();

      console.log("Backend Response: ", data);
      if (data.success === false) {
        console.log("Error uploading data:", data.message);
      }

      navigate("/homeworkhelp");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full lg:px-5">
      <h4 className="text-center font-serif mb-5">Reviewing your brief</h4>
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Subject</label>
          <div className="relative">
            <input
              id="subject"
              type="text"
              placeholder="Enter subject here"
              required
              className={inputClassValues}
              value={formData.subject}
              onChange={handleInputChange}
            />
            <img
              src={subject}
              alt="subject"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Type of paper</label>
          <div className="relative">
            <input
              id="paperType"
              type="text"
              placeholder="Enter type of paper"
              required
              className={inputClassValues}
              value={formData.paperType}
              onChange={handleInputChange}
            />
            <img
              src={topic}
              alt="topic"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <label className="text-sm text-gray-800">Topic</label>
        <div className="relative">
          <input
            id="topic"
            type="text"
            placeholder="Enter your topic here"
            required
            className={inputClassValues}
            value={formData.topic}
            onChange={handleInputChange}
          />
          <img
            src={topic}
            alt="topic"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="text-sm text-gray-800">Project details</label>
        <textarea
          id="details"
          rows="4"
          placeholder="Mention your topic, main idea & requirements"
          className={inputClassValues}
          value={formData.details}
          onChange={handleInputChange}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-3 mt-4">
        <div className="w-full lg:w-1/2">
          {activeTab === "pages" && (
            <label className="text-sm text-gray-800">Page count</label>
          )}
          {activeTab === "words" && (
            <label className="text-sm text-gray-800">Word count</label>
          )}
          <div className="flex items-center space-x-2 ">
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleDecrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                -
              </div>
            </div>
            {activeTab === "pages" && (
              <div className="mr-0">
                <input
                  id="pageCount"
                  type="number"
                  min="0"
                  className={inputClassValues}
                  value={formData.pageCount}
                  onChange={handleInputChange}
                />
              </div>
            )}
            {activeTab === "words" && (
              <div className="mr-0">
                <input
                  id="wordCount"
                  type="number"
                  min="0"
                  className={inputClassValues}
                  value={formData.wordCount}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleIncrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                +
              </div>
            </div>
          </div>
        </div>
        {/* Page / word count tab here */}
        <div className="flex border border-[#2BC210] h-10 mt-10 mr-3 w-fit">
          <div
            className={`border border-[#2BC210] hover:bg-[#439F46] hover:text-white p-2 justify-center items-center text-center text-sm cursor-pointer ${
              activeTab === "pages" ? "bg-[#439F46] text-white" : "bg-[#f5f5f5]"
            }`}
            onClick={() => handleTabClick("pages")}
          >
            Pages
          </div>
          <div
            className={`border border-[#2BC210] hover:bg-[#439F46] hover:text-white p-2 justify-center items-center text-center text-sm cursor-pointer ${
              activeTab === "words" ? "bg-[#439F46] text-white" : "bg-[#f5f5f5]"
            }`}
            onClick={() => handleTabClick("words")}
          >
            Words
          </div>
        </div>

        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Slides</label>
          <div className="flex items-center space-x-2 ">
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSlidesInputDecrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                -
              </div>
            </div>
            <div className="relative">
              <input
                id="powerPointSlides"
                type="number"
                min="0"
                placeholder="Number of slides"
                className={inputClassValues}
                value={formData.powerPointSlides}
                onChange={handleInputChange}
              />
            </div>
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSlidesInputIncrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="relative">
          <input
            type="file"
            onChange={handleNewFileChange}
            multiple
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <div className="flex flex-col items-center justify-center border-2 border-[#b2f3a7] border-dotted w-full bg-[#f5f5f5] p-6 rounded-lg">
            <div className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center">
              <img src={upload} alt="upload" className="w-4 h-4" />
            </div>
            <p className="text-center mb-3 mt-3 text-gray-900 text-sm">
              Attach More files
            </p>

            {newFileNames.length > 0 && (
              <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
                {newFileNames.map((name, index) => (
                  <li key={index}>
                    {name}{" "}
                    {newUploadProgress[name] !== undefined &&
                      `(${newUploadProgress[name]}%)`}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {formData.files && formData.files.length > 0 && (
        <p className="flex flex-row">
          Uploaded files:{" "}
          <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
            {formData.files.map((file, index) => {
              const segments = file.split("/").pop().split("-"); // Split by "/" and then by "-"
              const fileName = segments.slice(1).join("-"); // Extracts the file name from the URL
              return (
                <li key={index} className="text-[#439F46] font-semibold ">
                  {fileName}
                </li>
              );
            })}
          </ul>
        </p>
      )}

      <div className="flex lg:flex-row flex-col gap-3 mt-4">
        <div className="w-full lg:w-1/2 mt-2">
          <label className="text-sm text-gray-800">Paper format</label>
          <div className="relative w-full">
            <select
              className={inputClassValues}
              id="format"
              value={formData.format}
              onChange={handleInputChange}
              required
            >
              <option value="MLA">MLA</option>
              <option value="APA">APA</option>
              <option value="Chicago">Chicago</option>
              <option value="Harvard">Harvard</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-2 lg:ml-5">
          <label className="text-sm text-gray-800">Sources count</label>
          <div className="flex items-center space-x-2 ">
            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSourceInputDecrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                -
              </div>
            </div>

            <input
              className={inputClassValues}
              type="number"
              min="0"
              placeholder="Sources count"
              id="noOfSources"
              value={formData.noOfSources}
              onChange={handleInputChange}
              required
            />

            <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#2BC211]">
              <div
                onClick={handleSourceInputIncrease}
                className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Budget */}
      <div className="w-full mb-8 mt-5">
        <h4 className="text-left text-dark font-semibold">Budget</h4>
        <div className="mt-2">
          <label className="text-sm text-gray-800">I am looking to spend</label>
          <div className="relative">
            <input
              id="price"
              className={inputClassValues}
              type="number"
              placeholder="Enter budget"
              value={formData.orderDetails.price}
              onChange={handleInputChange}
            />
            <img
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
              src={dollar}
              alt="budget"
            />
          </div>
          <p className="text-sm text-gray-800 text-right mt-1">
            Average range for this service $80 - $800
          </p>
        </div>
      </div>

      {/* Deadline */}
      <div className="w-full mt-4">
        <h4 className="text-left text-dark font-semibold">Deadline</h4>
        <div className="mt-2">
          <label className="text-sm text-gray-800">Let's talk timing</label>
          <div className="relative">
            <input
              id="deadline"
              className={inputClassValues}
              type="datetime-local"
              placeholder="Enter Deadline"
              value={formData.deadline}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={!uploadComplete}
        className={`px-6 py-3 rounded-lg mx-auto w-full flex justify-center items-center text-center mt-8 ${
          uploadComplete
            ? "bg-[#439F46] text-white hover:bg-[#2BC211]"
            : "bg-gray-3 text-gray-600 cursor-not-allowed"
        }`}
      >
        {!uploadComplete ? "Uploading files..." : "Submit"}
      </button>
    </form>
  );
}

export default HomeworkStep3;
