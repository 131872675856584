import React, { useState } from "react";
import tick from "../../../../../assets/svg_icons/green-tick.svg";
import { useSelector } from "react-redux";
import { baseURL } from "../../../../../config";
import { useNavigate } from "react-router-dom";
import BasicPackage from "../Packages/BasicPackage";
import PremiumPackage from "../Packages/PremiumPackage";
import StandardPackage from "../Packages/StandardPackage";

function Step2({ formData, setFormData, handleInputChange }) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const packageSteps = [
    {
      stepNumber: "1",
      stepTitle: "Basic",
      price: 199,
    },
    {
      stepNumber: "2",
      stepTitle: "Standard",
      price: 299,
    },
    {
      stepNumber: "3",
      stepTitle: "Premium",
      price: 399,
    },
  ];
  const [activeStep, setActiveStep] = useState(packageSteps[0]);
  const handlePackageChange = (step) => {
    setActiveStep(step);
    setFormData((prevData) => ({
      ...prevData,
      packageType: step.stepTitle,
      orderDetails: { price: step.price },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Resume:", formData);

    try {
      const res = await fetch(`${baseURL}/service/resume/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          ...formData,
          userRef: currentUser._id,
        }),
      });
      console.log(currentUser);

      const data = await res.json();

      console.log("Backend Response: ", data);
      if (data.success === false) {
        console.log("Error uploading data:", data.message);
      }

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  const thirdList = [
    "Preventing of spacing",
    "The original",
    "Preventing of spacing",
    "The original",
  ];
  return (
    <form className="w-full 2xl:px-5" onSubmit={handleSubmit}>
      <div className="flex flex-col lg:flex-row  gap-3">
        <div className="w-full lg:w-1/2 mt-2">
          <label className="text-sm text-gray-800">Choose level</label>
          <div className="relative w-full">
            <select
              className={inputClassValues}
              id="level"
              value={formData.level}
              onChange={handleInputChange}
              required
            >
              <option value="Entry" selected>
                Entry Level (0-2 years)
              </option>
              <option value="Mid">Mid Career Level ( 3+ years)</option>
              <option value="Executive">Executive Level</option>
            </select>
          </div>
        </div>
        {/* Deadline */}
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Select deadline</label>
          <div className="relative">
            <select
              className={inputClassValues}
              id="deadline"
              value={formData.deadline}
              onChange={handleInputChange}
              required
            >
              <option value="24-hrs" selected>
                24 hours
              </option>
              <option value="3-days">3 days</option>
              <option value="5-days">5 days</option>
              <option value="7-days">7 days</option>
              <option value="14-days">14 days</option>
            </select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 mt-4 gap-5">
        <div className="bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-lg">
          <div className="flex flex-row items-center gap-3">
            {packageSteps.map((step, index) => (
              <div key={index}>
                <input
                  id={`package-${index}`}
                  type="radio"
                  name="packageType"
                  value={step.stepTitle}
                  onChange={() => handlePackageChange(step)}
                  checked={activeStep.stepTitle === step.stepTitle}
                  className="w-4 h-4 text-[#2BC210]"
                />
                <label
                  htmlFor={`package-${index}`}
                  className={`ml-2 font-semibold cursor-pointer ${
                    activeStep.stepTitle === step.stepTitle
                      ? "text-success"
                      : ""
                  }`}
                >
                  {step.stepTitle}
                </label>
              </div>
            ))}
          </div>

          <div className="mt-3">
            {activeStep.stepNumber === "1" && <BasicPackage />}
            {activeStep.stepNumber === "2" && <StandardPackage />}
            {activeStep.stepNumber === "3" && <PremiumPackage />}
          </div>
        </div>
        {/* Amount to pay */}
        <div>
          <div className="bg-[#F7FBF7] shadow-md px-10 py-10 rounded-lg border border-[#E6F1E6]">
            <div className="flex flex-row justify-between">
              <p className="text-lg font-bold">Amount to pay</p>
              <p className="text-lg text-[#2BC210] font-extrabold">
                ${activeStep.price}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Standard Add on services */}
      <div className="text-lg font-bold ml-5 mt-6 mb-3">
        Standard add on services
      </div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transform duration-500 transform hover:-translate-y-1">
          <div className="flex  flex-row items-center">
            <div>
              <input
                checked={formData.coverLetter || false}
                onChange={handleInputChange}
                id="coverLetter"
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-3  text-md">
                Cover letter
              </label>
            </div>
            <div className="ml-5">
              <input
                checked={formData.linkedIn || false}
                onChange={handleInputChange}
                id="linkedIn"
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-5 text-md">
                Linked in
              </label>
            </div>
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>

          <div className="grid grid-cols-1 ">
            {thirdList.map((item, index) => (
              <div className="flex items-center" key={index}>
                <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
                <span className="text-sm">{item}</span>
              </div>
            ))}
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
          <div className="flex flex-row justify-between">
            <p className="text-md font-semibold">Price </p>
            <p className="text-lg text-[#2BC210] font-extrabold"> $99</p>
          </div>
          <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold"></div>
        </div>
      </div>
      {/* Standard Add ons*/}
      <div className="text-lg font-bold ml-5 mt-6 mb-3">Standard addons</div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transform duration-500 transform hover:-translate-y-1">
          <div className="flex  flex-row items-center">
            <div>
              <input
                checked={formData.professionalBio || false}
                onChange={handleInputChange}
                id="professionalBio"
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-3  text-md">
                Professional Bio
              </label>
            </div>
            <div className="ml-5">
              <input
                checked={formData.thankYou || false}
                onChange={handleInputChange}
                id="thankYou"
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-5 text-md">
                Thank You Letter
              </label>
            </div>
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>

          <div className="grid grid-cols-1 ">
            {thirdList.map((item, index) => (
              <div className="flex items-center" key={index}>
                <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
                <span className="text-sm">{item}</span>
              </div>
            ))}
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
          <div>
            <input
              type="checkbox"
              className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
            />
            <label htmlFor="custom-checkbox" className="ml-3  text-sm">
              (150 words ) $99
            </label>
          </div>
          <div className="mt-4">
            <input
              type="checkbox"
              className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
            />
            <label htmlFor="custom-checkbox" className="ml-3 text-sm">
              (300 words ) $199
            </label>
          </div>
          <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold"></div>
        </div>
      </div>
      {/* Next Button */}
      <button
        type="button"
        onClick={handleSubmit}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Submit & Pay
      </button>
    </form>
  );
}

export default Step2;
