import React from "react";
import StepRow from "./StepRow";

function HowWeWork() {
  return (
    <section
      className="bg-[#f7fbf7]
      centered-section "
    >
      <div className="container mx-auto px-3 lg:px-20 py-12 justify-center ">
        <div className="section-title">
          <h2 className="font-bold text-3xl mb-10">How it works</h2>
          <p>A smooth and straightforward approach to getting started</p>
        </div>
        <StepRow />
      </div>
    </section>
  );
}

export default HowWeWork;
