import React from "react";
import { Link } from "react-router-dom";

function OrderNav() {
  const orderStates = [
    { label: "Buyer Request", count: 1, url: "/homeworks-to-bid" },
    { label: "Available Orders", count: 3, url: "/unpaid" },
    { label: "In Progress", count: 2, url: "/inprogress" },
    { label: "Delivered", count: 10, url: "/delivered" },
    { label: "Approved", count: 100, url: "/approved" },
    { label: "Cancelled", count: 0, url: "/cancelled" },
  ];

  return (
    <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden md:max-w-2xl mt-5">
      <div className="p-4 bg-[#E9E9E9] text-black text-lg font-bold">
        Manage Order
      </div>
      <div className="p-6 grid grid-cols-4 gap-4">
        {orderStates.map((state, index) => (
          <React.Fragment key={index}>
            <Link
              to={state.url}
              className="!text-gray-700 flex  font-semibold justify-start col-span-3"
            >
              {state.label}
            </Link>
            <div className="flex items-center justify-end">
              <p className="font-bold"> {state.count}</p>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default OrderNav;
