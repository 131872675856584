import React from "react";
import DashboardCard from "../../Dashboard/DashboardCard";
import HorizontalDivider from "../../Divider/HorizontalDivider";
import VerticalDivider from "../../Divider/VerticalDivider";

function OrderStatus() {
  return (
    <DashboardCard
      cardTitle={
        <div className="flex flex-row items-start justify-between gap-3 ">
          <div className="text-pretty font-bold">Order status</div>

          <button
            type="button"
            className="px-6 py-1 text-sm text-[#439F46] border border-[#439F46] bg-[#FFFFFF] cursor-pointer font-semibold rounded-[6px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] "
          >
            In progress
          </button>
        </div>
      }
      cardContent={
        <div>
          <HorizontalDivider color="#DDEFDE" />
          {/* Amount  And tip */}
          <div className="flex justify-between items-start mt-8">
            {/* Amount */}
            <div className="flex flex-col">
              <div className="text-center mb-3 text-sm">Amount</div>
              <div className="text-center text-md font-semibold">$40.00</div>
            </div>

            {/* Vertical Divider */}
            <VerticalDivider height="h-12" color="#E3E6F2" />

            {/* Tip */}
            <div className="flex flex-col">
              <div className="text-center mb-3 text-sm">Tip</div>
              <div className="text-center text-md font-semibold">$4.00</div>
            </div>
          </div>
          {/* Total: Amount + Tip */}
          <div className="relative p-3 mx-auto w-full mt-10 rounded-[20px] border-[#484B48]">
            <div className="absolute inset-0 bg-[#484B48] opacity-[0.05] rounded-[10px] pointer-events-none"></div>
            <div className="relative">
              {/*specific order detail */}
              <div className="flex flex-row gap-2 justify-between my-auto">
                <span className="text-sm">Total</span>
                <span className="text-sm font-semibold  text-[#439F46] ">
                  $44.00
                </span>
                <button
                  type="button"
                  className="px-6 py-1 text-sm text-[#439F46] border border-[#439F46] bg-[#FFFFFF] cursor-pointer font-semibold rounded-[6px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)] "
                >
                  Paid
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default OrderStatus;
