import React from "react";
import upload from "../../../../assets/svg_icons/upload.svg";
import StructureCheckCard from "../../../../components/StructureCheckCard";
import { useSelector } from "react-redux";
import { baseURL } from "../../../../config";
import { useNavigate } from "react-router-dom";

function Step1({
  formData,
  handleFileChange,
  handleInputChange,
  fileNames,
  uploadProgress,
}) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const addon = {
    title: "",
    checkBoxVisible: false,

    itemsList: [
      "Spell check, punctuation rectification, grammar, terminology, syntax, style & tone (overuse of passive voice and wordiness), consistency and finally restructuring text to ensure flow.",
      " Guaranteed 100% quality and on-time delivery, with same-day delivery convenience.",
      "File with Word Track changes and report with detailed feedback and advice on writing style.",
      "An Editor Summary Report.",
      "Subject specific proofreading.",
    ],
    tagLine: " ",
  };
  // Handler functions to change the active tab
  const handleIncrease = () => {
    handleInputChange({
      target: {
        id: "noOfSources",
        value: formData.noOfSources + 1,
      },
    });
  };
  const handleDecrease = () => {
    handleInputChange({
      target: {
        id: "noOfSources",
        value: Math.max(0, formData.noOfSources - 1),
      },
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Citation:", formData);

    try {
      const res = await fetch(`${baseURL}/service/citation/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ ...formData, userRef: currentUser._id }),
      });

      console.log(currentUser);

      const data = await res.json();

      console.log("Backend Response: ", data);
      if (data.success === false) {
        console.log("Error uploading data:", data.message);
      }

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  return (
    <form className="w-full lg:px-5">
      <div className="flex lg:flex-row flex-col gap-3">
        <div className="w-full">
          <div className="mt-4">
            <label className="text-sm text-gray-800">Project brief</label>
            <textarea
              id="details"
              rows="4"
              placeholder="Mention your topic, main idea & requirements"
              className={inputClassValues}
              value={formData.details}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="flex lg:flex-row flex-col gap-3 mt-4">
            <div className="w-full lg:w-1/2 mt-2">
              <label className="text-sm text-gray-800">Paper format</label>
              <div className="relative w-full">
                <select
                  className={inputClassValues}
                  id="format"
                  value={formData.format}
                  onChange={handleInputChange}
                  required
                >
                  <option value="MLA">MLA</option>
                  <option value="APA">APA</option>
                  <option value="Chicago">Chicago</option>
                  <option value="Harvard">Harvard</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="w-full lg:w-1/2 mt-2 lg:ml-5">
              <label className="text-sm text-gray-800">Sources count</label>
              <div className="flex items-center space-x-2 ">
                <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#439F46]">
                  <div
                    onClick={handleDecrease}
                    className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
                  >
                    -
                  </div>
                </div>

                <input
                  className={inputClassValues}
                  type="number"
                  min="0"
                  placeholder="Sources count"
                  id="noOfSources"
                  value={formData.noOfSources}
                  onChange={handleInputChange}
                  required
                />

                <div className="p-2 bg-[#439F46] rounded-full hover:bg-[#439F46]">
                  <div
                    onClick={handleIncrease}
                    className="w-2 h-2 flex items-center justify-center rounded-full text-white font-bold cursor-pointer"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="relative">
              <input
                type="file"
                onChange={handleFileChange}
                multiple
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              />
              <div className="flex flex-col items-center justify-center border-2 border-[#b2f3a7] border-dotted w-full bg-[#f5f5f5] p-6 rounded-lg">
                <div className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center">
                  <img src={upload} alt="upload" className="w-4 h-4" />
                </div>
                <p className="text-center mb-3 mt-3 text-gray-900 text-sm">
                  Attach files
                </p>
                {fileNames.length > 0 && (
                  <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
                    {fileNames.map((name, index) => (
                      <li key={index}>
                        {name}{" "}
                        {uploadProgress[name] !== undefined &&
                          `(${uploadProgress[name]}%)`}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-full">
          <StructureCheckCard
            key={addon.id}
            cardLabel={addon.title}
            items={addon.itemsList}
            tagLine={addon.tagLine}
            checkBoxVisible={addon.checkBoxVisible}
            title={addon.flexTitle}
          />
        </div>
      </div>

      <button
        type="button"
        onClick={handleSubmit}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Submit & Pay ($10.00)
      </button>
    </form>
  );
}

export default Step1;
