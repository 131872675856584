import React from "react";
import ClientInformationCard from "./ClientInformationCard";
import WriterInformationCard from "./WriterInformationCard";
import EditorInformationCard from "./EditorInformationCard";

function OrderUsersDetails() {
  return (
    <div className="mt-4 bg-[#FFFFFF] rounded-[25px] border border-[#E4EFE4] lg:p-5 p-2 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-2">
      {/* Client Information */}
      <ClientInformationCard />

      {/* Writer Information */}
      <WriterInformationCard />

      {/* Editor Information */}
      <EditorInformationCard />
    </div>
  );
}

export default OrderUsersDetails;
