import React from "react";
import arrow from "../../assets/icons/arrow.png";

function SingleSkill({ skillImage, skillTitle, skillDescription, skillLink }) {
  return (
    <div className="flex flex-col rounded-lg shadow-md overflow-hidden bg-white p-4 transition-transform duration-500 transform hover:translate-y-2">
      <img src={skillImage} className="w-full h-auto" alt={skillTitle} />
      <div className="mt-4">
        <h4 className="text-lg font-semibold text-gray-800">{skillTitle}</h4>
        <p className="text-sm text-gray-600">{skillDescription}</p>
        <a href={skillLink} className="flex items-center mt-2 text-green-500 hover:text-green-600">
          <span className="text-sm">Learn More</span>
          <img src={arrow} alt="arrow" className="ml-1 w-4 h-4" />
        </a>
      </div>
    </div>
  );
}

export default SingleSkill;
